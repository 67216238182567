import Discrete from "../../app.services/model/discrete.mjs";

app.directive('discrete.text', [
  /**
   */
  () => {
    return {
      scope: {
        prop: '@',
        service: '@',
        get: '@',
        set: '@',
        watch: '@',
        placeholder: '@',
        aria: '@'
      },
      restrict: 'E',
      replace: true,
      templateUrl: './app.directives/controls/text.html',
      link: ($scope, name) => {
        const defaultFn = () => {
          $scope.model = null;
        };

        const discrete = new Discrete($scope, defaultFn);

        let is_input_selected = false;

        $scope.blur = () => {
          is_input_selected = false;
        };

        $scope.select = () => {
          if (!is_input_selected) {
            name.find('input')[0].select();
          }

          is_input_selected = true;
        };

        $scope.$watch('model', () => {
          discrete.set();
        });

        $scope.$on('$destroy', () => {
          discrete.destroy();
        });
      }
    };
  }
]);
