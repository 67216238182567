import Scroll from "../core/scroll.mjs";

export default class HTMLOBlock extends Scroll {
  constructor() {
    super();

    this.size = this.getAttribute('size');
    this.color = this.getAttribute('color');
    this.offset = this.getAttribute('offset');
    this.has_skew = this.hasAttribute('skew');
  }

  connectedCallback() {
    this.classList.add(this.size);
    this.classList.add(this.color);
    this.classList.add(`${this.offset}-offset`);

    if (this.has_skew) {
      this.classList.add('skew');
    }

    if (this.hasAttribute('scroll')) {
      this.watchScroll(this.getAttribute('scroll'));
    }
  }

  disconnectedCallback() {
    this.unwatchScroll();
  }
}

window.customElements.define('o-block', HTMLOBlock);