/**
 * @class Socket
 */
class Socket {
  /**
   * @param {ManageUser} manageUser
   * @param {ManageUsersPresence} manageUsersPresence
   * @param {ManageProjectEditor} manageProjectEditor
   * @param {ManageDesign} manageDesign
   * @param {ManageComments} manageComments
   * @param {ManageNotifications} manageNotifications
   */
  constructor(manageUser, manageUsersPresence, manageProjectEditor, manageDesign,
    manageComments, manageNotifications) {
    this.query_queue = [];
    this.path = `ws://${window.location.hostname}:4000/graphql`;
    this.gql = {
      CONNECTION_INIT: 'connection_init',
      CONNECTION_ACK: 'connection_ack',
      CONNECTION_ERROR: 'connection_error',
      CONNECTION_KEEP_ALIVE: 'ka',
      START: 'start',
      STOP: 'stop',
      CONNECTION_TERMINATE: 'connection_terminate',
      DATA: 'data',
      ERROR: 'error',
      COMPLETE: 'complete'
    };
    this.ws = null;
    this.is_active = false;
    this.manageUser = manageUser;
    this.manageUsersPresence = manageUsersPresence;
    this.manageProjectEditor = manageProjectEditor;
    this.manageDesign = manageDesign;
    this.manageComments = manageComments;
    this.manageNotifications = manageNotifications;

    this.timer = null;
    this.token = null;
  }

  connect(token) {
    if (this.is_active) {
      return;
    }

    this.token = token;

    /**
     * Retry on the socket connection will throw. Should secure the re-connection
     * to check availability before trying to connect. Use other libs for reference.
     */
    this.ws = new WebSocket(this.path, 'graphql-ws');

    this.ws.onerror = () => this.onerror();
    this.ws.onopen = () => this.onopen();
    this.ws.onmessage = event => this.onmessage(event);
    this.ws.onclose = () => this.onclose();

    /**
     * Subscribe to managers for connection requests.
     */
    this.manageProjectEditor.watchConnection((project_id, version) => {
      this.design(project_id, version, this.token);
      this.project(project_id, this.token);
    });
  }

  onerror() {
    this.is_active = false;

    this.connectionLost();
    this.reconnect();
  }

  onopen() {
    this.ws.send(JSON.stringify({
      type: this.gql.CONNECTION_INIT,
      payload: { token: this.token }
    }));
  }

  onmessage(event) {
    const data = JSON.parse(event.data);

    switch (data.type) {
      case this.gql.CONNECTION_ACK: {
        this.is_active = true;

        this.activateUser(this.token);
        this.loadQueue();

        /**
         * Register leave functions in managers.
         */
        this.manageUsersPresence.registerLeaveUsers(this.leave.bind(this), 'users');
        this.manageProjectEditor.registerLeaveProject(this.leave.bind(this), 'project');
        this.manageDesign.registerLeaveDesign(this.leave.bind(this), 'design');
        this.manageComments.registerLeaveComments(this.leave.bind(this), 'comments');
        this.manageNotifications.registerLeaveNotifications(this.leave.bind(this), 'notifications');

        this.manageDesign.syncDesign();

        break;
      }
      case this.gql.CONNECTION_ERROR: {
        this.is_active = false;
        break;
      }
      case this.gql.CONNECTION_TERMINATE: {
        this.is_active = false;
        break;
      }
      case this.gql.CONNECTION_KEEP_ALIVE: {
        console.log('keep alive');
        break;
      }
      case this.gql.DATA: {
        this.propagateMessages(data);
        break;
      }
      case this.gql.ERROR: {
        console.log('error', data);
        break;
      }
      case this.gql.COMPLETE: {
        console.log(`Disconnected from ${data.id}.`);
        break;
      }
      default:
        break;
    }
  }

  onclose() {
    this.is_active = false;

    this.connectionLost();
    this.reconnect();
  }

  connectionLost() {
    this.manageUsersPresence.updateLost();
  }

  reconnect() {
    const check = () => {
      if (!this.is_active && !document.hidden) {
        this.connect(this.token);

        clearTimeout(this.timer);
      }

      this.timer = setTimeout(() => {
        check();
      }, 10000);
    };

    if (!this.timer) {
      check();
    }
  }

  propagateMessages(data) {
    if (data.id === 'users' && data?.payload?.data) {
      this.manageUsersPresence.updateActive(data.payload.data.users);
    }

    if (data.id === 'project' && data?.payload?.data) {
      this.manageProjectEditor.joinProject(data.payload.data.project);
    }

    if (data.id === 'design' && data?.payload?.data) {
      this.manageDesign.updateLive(data.payload.data.design);
    }

    if (data.id === 'comments' && data?.payload?.data) {
      this.manageComments.updateComments(data.payload.data.comments);
    }

    if (data.id === 'notifications' && data?.payload?.data) {
      this.manageNotifications.updateNotifications(
        data.payload.data.notifications
      );
    }

    // console.log('socket', data.id, data.payload.errors, data.payload.data);
  }

  disconnect() {
    if (this.ws) {
      this.ws.send(JSON.stringify({
        type: this.gql.CONNECTION_TERMINATE
      }));
    }
  }

  loadQueue() {
    for (const query_item of this.query_queue) {
      this.join(query_item.query, query_item.id, query_item.variables);
    }
  }

  addToQueue(query, id, variables) {
    const query_item = { query, id, variables };

    this.query_queue.push(query_item);
  }

  join(query, id, variables) {
    if (this.ws && this.is_active) {
      this.ws.send(JSON.stringify({
        type: this.gql.START,
        id,
        payload: { query, variables }
      }));
    } else {
      this.addToQueue(query, id, variables);
    }
  }

  leave(id) {
    if (this.ws && this.is_active) {
      this.ws.send(JSON.stringify({
        type: this.gql.STOP,
        id
      }));
    }
  }

  activateUser(token) {
    const query = `
      subscription users($token: String!) {
        users(token: $token) {
          _id
        }
      }
    `;

    this.join(query, 'users', { token });
  }

  project(project_id, token) {
    const query = `
      subscription project($project_id: ID!, $token: String!) {
        project(project_id: $project_id, token: $token) {
          _id
          full_name
          display_name
          avatar
          avatar_virtual
          avatar_primary_color
        }
      }
    `;

    this.join(query, 'project', { project_id, token });
  }

  design(project_id, version, token) {
    const query = `
      subscription design($project_id: ID!, $version: String!, $token: String!) {
        design(project_id: $project_id, version: $version, token: $token) {
          user
          type
          created_at
          inform_source
          index
          edits {
            element_id
            key
            operation
            value
          }
        }
      }
    `;

    this.join(query, 'design', { project_id, version, token });
  }

  comments(project_id, token) {
    const query = `
      subscription comments($project_id: ID!, $token: String!) {
        comments(project_id: $project_id, token: $token) {
          _id
          text
          project
          element
          user {
            _id
            full_name
            display_name
            avatar
            avatar_virtual
            avatar_primary_color
          }
          seen
          state
          emojis {
            user {
              _id
            }
            emoji {
              _id
              name
              path
            }
          }
          created_at
        }
      }
    `;

    this.join(query, 'comments', { project_id, token });
  }

  notifications(token) {
    const query = `
      subscription notifications($token: String!) {
        notifications(token: $token) {
          _id
          title
          short_description
          description
          link
          placement
          trigger
          icon
          icon_color
          is_active
          created_at
          updated_at
        }
      }
    `;

    this.join(query, 'notifications', { token });
  }
}

app.service('socket', ['manageUser', 'manageUsersPresence', 'manageProjectEditor',
  'manageDesign', 'manageComments', 'manageNotifications', Socket]);