import meta from "../../app.services/route/meta.mjs";

function MacOsCtrl() {
  meta.title('Download MacOS');
  // meta.colors([
  //   { min: 0, max: 60, color: undefined },
  //   { min: 60, max: 500, color: 'purple' },
  //   { min: 500, color: 'light' }
  // ]);
}

MacOsCtrl.$inject = [];
MacOsCtrl.resolve = {};

app.controller('MacOsCtrl', MacOsCtrl);
window.MacOsCtrl = MacOsCtrl;
