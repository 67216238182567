// eslint-disable-next-line no-undef
export default class HTMLOColorInput extends HTMLElement {
  constructor() {
    super();

    /** @type {HTMLElement} */
    this.inputRoot = null;

    /** @type {HTMLElement} */
    this.boxRoot = null;

    this._value = undefined;
  }

  /**
   * @param {string} value
   */
  set value(value) {
    this._value = value;

    this.renderValue();
  }

  /**
   * @returns {string | undefined}
   */
  get value() {
    return this._value;
  }

  renderValue() {
    this.inputRoot.innerHTML = this._value;
    this.boxRoot.style.background = this._value;
  }

  connectedCallback() {
    this.innerHTML = /*html*/`
    <div class="box"></div>
    <div class="input">${this.getAttribute('value')}</div>`;

    this.boxRoot = this.querySelector('div.box');
    this.inputRoot = this.querySelector('div.input');

    this.boxRoot.style.background = this.getAttribute('value');
  }
}

window.customElements.define('o-color-input', HTMLOColorInput);