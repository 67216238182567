import { Store } from '../store.mjs';

/**
 * @class StoreUser
 */
export class StoreUser extends Store {
  addUser(user) {
    if (user) {
      this.localStorage.setItem(`${this.prefix}/user`, this.stringify(user));
    }
  }

  updateUser(user_fragment) {
    if (user_fragment) {
      const user = this.localStorage.getItem(`${this.prefix}/user`);
      const parsed_user = this.parse(user);
      const combined = { ...parsed_user, ...user_fragment };

      this.localStorage.setItem(`${this.prefix}/user`, this.stringify(combined));
    }
  }

  getUser() {
    const user = this.localStorage.getItem(`${this.prefix}/user`);

    return this.parse(user);
  }

  removeUser() {
    this.localStorage.removeItem(`${this.prefix}/user`);
  }
}

const storeUser = new StoreUser();

export default storeUser;