import Discrete from "../../app.services/model/discrete.mjs";
import keyboard from "../../app.services/keyboard/keyboard.mjs";

app.directive('discrete.list', ['$injector', 'view', 'toolList',
  /**
   * @param {*} $injector
   * @param {View} view
   * @param {ToolList} toolList
   */
  ($injector, view, toolList) => {
    return {
      scope: {
        prop: '@',
        service: '@',
        get: '@',
        set: '@',
        watch: '@',
        list: '=', // String referring to pre-populate collection or actual list.
        populate: '@', // Function name used to populate list from service.
        optionlabel: '@',
        optionid: '@',
        default: '@'
      },
      restrict: 'E',
      replace: true,
      templateUrl: './app.directives/controls/list.html',
      link: async ($scope, list) => {
        const defaultFn = () => {
          $scope.model = $scope.default ? $scope.default : null;
        };

        const discrete = new Discrete($scope, defaultFn);

        const close = () => {
          $scope.is_on = false;

          keyboard.remove('Escape', null);
        };

        const setup = async() => {
          if ($scope.service && $injector.has($scope.service)) {
            const service = $injector.get($scope.service);

            if (service[$scope.populate]) {
              return service[$scope.populate]();
            } else {
              throw `Unable to populate list.discrete with ${$scope.populate} function.`;
            }
          } else {
            throw `Unable to find ${$scope.service} service for the list.discrete control.`;
          }
        };

        if (typeof $scope.list === 'string') {
          $scope.item_list = $scope.list ? toolList[$scope.list] : [];
        } else {
          $scope.item_list = $scope.list;
        }

        if ($scope.populate) {
          $scope.item_list = await setup();
        }

        $scope.selected_index = $scope.item_list?.findIndex(
          item => item[$scope.optionid] === $scope.model
        );

        $scope.getLabel = value => {
          const label = $scope.item_list ? $scope.item_list.find(item => item[$scope.optionid] === value): '';

          if (label) {
            return label[$scope.optionlabel];
          }
        };

        $scope.toggle = () => {
          $scope.is_on = !$scope.is_on;

          if ($scope.is_on) {
            keyboard.add('Escape', null, () => {
              $scope.$applyAsync(() => {
                close();
                view.removeClickToClose();
              });
            });
          }

          view.addClickToClose(list, () => {
            $scope.$applyAsync(() => {
              close();
            });
          });
        };

        $scope.selectProperty = (item, index) => {
          $scope.model = item;
          $scope.selected_index = index;

          close();
          discrete.set();
          view.removeClickToClose();
        };

        $scope.$on('$destroy', () => {
          discrete.destroy();
        });
      }
    };
  }
]);
