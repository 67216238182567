import meta from "../../app.services/route/meta.mjs";

function TermsCtrl() {
  meta.title('Terms');
}

TermsCtrl.$inject = [];
TermsCtrl.resolve = {};

app.controller('TermsCtrl', TermsCtrl);
window.TermsCtrl = TermsCtrl;
