/**
 * Base manager structure.
 * @class ManagerBase
 */
export default class ManagerBase {
  constructor() {
    this.props = {};

    /** @private */
    this.watchFns = [];
    /** @private */
    this.watchConnectionFns = [];
  }

  /**
   * Registers an observer with the manager.
   * @param {OWatchFn} fn
   */
  watch(fn) {
    this.watchFns.push(fn);

    this.trigger();
  }

  /**
   * Registers a socket connection observer with the manager.
   * @param {OWatchFn} fn
   */
  watchConnection(fn) {
    this.watchConnectionFns.push(fn);
  }

  /**
   * Removes an observer from the manager.
   * @param {OWatchFn} fn
   */
  unwatch(fn) {
    for (const [index, watchFn] of this.watchFns.entries()) {
      if (watchFn === fn) {
        this.watchFns.splice(index, 1);
      }
    }
  }

  /**
   * Removes a connection observer from the manager.
   * @param {OWatchFn} fn
   */
  unwatchConnection(fn) {
    for (const [index, watchFn] of this.watchConnectionFns.entries()) {
      if (watchFn === fn) {
        this.watchConnectionFns.splice(index, 1);
      }
    }
  }

  /**
   * Triggers all registered observers with an update.
   */
  trigger() {
    for (const fn of this.watchFns) {
      fn(this.props);
    }
  }

  /**
   * Triggers all registered observers when manager is requesting a socket connection.
   * @param {*} args
   */
  triggerConnection(...args) {
    for (const fn of this.watchConnectionFns) {
      fn.apply(null, args);
    }
  }

  /**
   * Populates manager with service props, typically from another
   * service holding a collection.
   * @param {OProps} props
   */
  async setup(props) {
    this.clear();
    this.props = props;
    this.trigger();
  }

  /**
   * Removes duplicate object from an array based on the _id.
   * @param {*} array
   */
  deduplicate(array) {
    return array.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t._id === value._id
      ))
    )
  }

  /**
   * Returns property by name.
   * @param {string} name
   */
  get(name) {
    if (this.props) {
      return this.props[name];
    }
  }

  /**
   * Sets property by name.
   * @param {string} name
   * @param {OProp} prop
   */
  set(name, prop) {
    if (!this.props) {
      this.props = {};
    }

    this.props[name] = prop;
    this.trigger();
  }

  /**
   * Sets property by name and updates, typically against an API.
   * @param {string} name
   * @param {OProp} prop
   */
  setAndUpdate(name, prop) {
    if (!this.props) {
      this.props = {};
    }

    this.props[name] = prop;
    this.trigger();
    this.update();
  }

  /**
   * Adds new item to collection and possibly filters result.
   * When the name is omitted, item will be added to 'raw'.
   * @param {ORecord} props
   * @param {string} [name]
   */
  addToCollection(props, name) {
    if (!this.props[name ? name : 'raw']) {
      this.props[name ? name : 'raw'] = [props];
    } else {
      this.props[name ? name : 'raw'].push(props);
    }

    this.filter();
  }

  /**
   * Updates collection with item and possibly filters result.
   * When the name is omitted, item will be updated in 'raw'.
   * @param {ORecord} props
   * @param {string} [name]
   */
  updateCollection(props, name) {
    const collection = name ? this.props[name] : this.props.raw;

    if (collection?.length) {
      collection.forEach((item, index) => {
        if (item._id === props._id) {
          collection[index] = props;
        }
      });

      this.filter();
    }
  }

  /**
   * Removes item from collection and possibly filters result.
   * When key and name are omitted, item will be removed from 'raw' by _id.
   * @param {ORecord} props Object to compare against to be removed.
   * @param {string} [key] Namespace to find match.
   * @param {string} [name] Name of the collection within the props.
   */
  removeFromCollection(props, key, name) {
    const collection = name ? this.props[name] : this.props.raw;

    if (collection?.length) {
      collection.forEach((item, index) => {
        const compare = key ? item[key]._id : item._id;

        if (compare === props._id) {
          collection.splice(index, 1);
        }
      });

      this.filter();
    }
  }

  /**
   * Clears manager props.
   */
  clear() {
    this.props = {};

    this.trigger();
  }

  filter() {}

  update() {}
}
