import viewScroll from "../../app.services/view/scroll.mjs";

export default class HTMLOHeader extends HTMLElement {
  constructor() {
    super();

    this.viewScroll = viewScroll;

    /**
     * Bind scope.
     */
    this.setColors = this.setColors.bind(this);
  }

  /**
   * Applies a color name on the header.
   * @param {OScrollProps} props
   */
  setColors(props) {
    this.className = '';

    if (props?.color) {
      this.classList.add(props.color);

      return;
    }

    if (props.is_scroll_middle) {
      this.classList.add('light');
    }
  }

  connectedCallback() {
    this.viewScroll.watch(this.setColors);
  }
}

window.customElements.define('o-header', HTMLOHeader);