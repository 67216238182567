/**
 * @class Common
 * Aggregate of utility functions.
 */
class Common {
  /**
   * @param {CommonGenerate} commonGenerate
   * @param {CommonEvent} commonEvent
   * @param {CommonSystem} commonSystem
   */
  constructor(commonGenerate, commonEvent, commonSystem) {
    this.generate = commonGenerate;
    this.event = commonEvent;
    this.system = commonSystem;
  }
}

app.service('common', ['commonGenerate', 'commonEvent', 'commonSystem', Common]);