import path from "./path.mjs";
import store from "../app.services/store/store.mjs";

window.app = angular.module('octo', ['ui.router']);
window.app.routeStore = '';
window.app.domain = window.location.origin;

app.run(['$rootScope', '$transitions', ($rootScope, $transitions) => {
  $rootScope.editor_domain = window.api.editor_domain;
  $rootScope.is_mobile_menu_on = false;

  /**
   * For main mobile menu.
   */
  $rootScope.toggleMenu = () => {
    $rootScope.is_mobile_menu_on = !$rootScope.is_mobile_menu_on;
  };

  $rootScope.close = () => {
    $rootScope.is_mobile_menu_on = false;
  }

  $transitions.onSuccess({}, () => {
    const hash = window.location.hash;

    if (hash == '') {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  });
}]);