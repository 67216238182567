export default class HTMLOGradient extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.color_1 = this.getAttribute('color-1');
    this.color_2 = this.getAttribute('color-2');

    if (this.color_1 && this.color_2) {
      this.style.background = `linear-gradient(45deg, ${this.color_1} 0%, ${this.color_2} 100%)`;
    }
  }
}

window.customElements.define('o-gradient', HTMLOGradient);