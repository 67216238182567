import manageBilling from "../../app.services/managers/billing.mjs";

app.directive('pricing.features', [
  /**
   */
  () => {
    return {
      scope: {
        interval: '@'
      },
      restrict: 'E',
      replace: true,
      templateUrl: './app.directives/pricing/features.html',
      link: $scope => {
        $scope.combined_features = [];

        $scope.display = () => {
          if ($scope.plans?.length === 1) {
            return 'one';
          }

          if ($scope.plans?.length === 2) {
            return 'two';
          }

          return 'three';
        };

        /**
         * Combines all features into one unique list.
         */
        const combine = plans => {
          const features = [];

          for (const plan of plans) {
            if (plan.features.length) {
              features.push(...plan.features);
            }
          }

          const unique_features = [];
          const map = new Map();

          for (const item of features) {
            if(!map.has(item.name)){
              map.set(item.name, true);

              unique_features.push({
                name: item.name,
                is_available: item.is_available,
                short_description: item.short_description,
                description: item.description
              });
            }
          }

          $scope.combined_features = unique_features;
        };

        const watchPlans = props => {
          $scope.$applyAsync(() => {
            if (props.plans) {
              $scope.plans = props.plans.filter(plan => plan.is_active);

              combine($scope.plans);
            }
          });
        };

        manageBilling.watch(watchPlans);

        $scope.getColor = (feature, plan) => {
          if (!feature) {
            return '';
          }

          return plan.primary_color ? plan.primary_color : plan.accent_color;
        };

        $scope.getFeature = (features, name) => {
          const feature = features.find(feature => feature.name === name);

          return feature ? feature.is_available : false;
        };

        $scope.getPath = (plan, interval) => {
          const path = 'main.account.billing-checkout';
          const link = `${window.api.editor_domain}?path=${path}&plan=${plan}&interval=${interval}`;

          return encodeURI(link);
        };

        $scope.$on('$destroy', () =>  {
          manageBilling.unwatch(watchPlans);
        });
      }
    };
  }
]);
