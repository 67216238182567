// eslint-disable-next-line no-undef
export default class HTMLOModal extends HTMLElement {
  constructor() {
    super();

    /** @type {HTMLElement} */
    this.modalRoot = undefined;
  }

  /**
   * Sets the modal type and on state.
   * @param {string} [modal]
   * @returns
   */
  setModal(modal) {
    if (modal === 'elements') {
      this.modalRoot.innerHTML = this.renderElements();

      this.style.display = 'flex';
      this.classList.add('lower');
      this.classList.remove('mask');

      return;
    }

    if (modal === 'new-project') {
      this.modalRoot.innerHTML = this.renderNewProject();

      this.style.display = 'flex';
      this.classList.remove('lower');
      this.classList.add('mask');

      return;
    }

    this.style.display = 'none';
  }

  renderNewProject() {
    return /*html*/`
    <div class="header"></div>
    <div class="body side">
      <div class="side">
        <div class="label on">Details</div>
        <div class="label">Code</div>
        <div class="label">Cover</div>
      </div>
      <div class="body">
        <div class="field">
          <div class="label">Name</div>
          <o-text-input></o-text-input>
        </div>
        <o-description-input></o-description-input>
        <div class="field">
          <div class="label">Organization</div>
          <o-dropdown-input></o-dropdown-input>
        </div>
        <div class="field">
          <div class="label">Public</div>
          <o-toggle-button></o-toggle-button>
        </div>
        <div class="field">
          <div class="label">Listed</div>
          <o-toggle-button></o-toggle-button>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="figure-button save">Create</div>
      <div class="figure-button cancel">Cancel</div>
    </div>`;
  }

  /**
   * Renders elements content.
   * @returns {string}
   */
  renderElements() {
    return /*html*/`
    <div class="header">
      <div class="on">Elements</div>
      <div>Components</div>
    </div>
    <div class="body elements">
      <div class="list">
        <div id="figure-modal-element-1" class="hover">
          <div class="icon fas fa-browser orange"></div>
          <div class="label">Page</div>
          <div class="type">&lt;div&gt;</div>
        </div>
        <div id="figure-modal-element-2">
          <div class="icon fas fa-square blue"></div>
          <div class="label">div</div>
          <div class="type">&lt;div&gt;</div>
        </div>
        <div id="figure-modal-element-3">
          <div class="icon fas fa-text blue"></div>
          <div class="label">label</div>
          <div class="type">&lt;label&gt;</div>
        </div>
        <div id="figure-modal-element-4">
          <div class="icon far fa-square purple"></div>
          <div class="label">span</div>
          <div class="type">&lt;span&gt;</div>
        </div>
        <div id="figure-modal-element-5">
          <div class="icon far fa-heading purple"></div>
          <div class="label">Heading</div>
          <div class="type">&lt;h&gt;</div>
        </div>
      </div>
      <div class="detail">
        <div class="icon fas fa-browser"></div>
        <div class="label">Page</div>
        <div class="description">Fixed-size content container.</div>
      </div>
    </div>
    `;
  }

  connectedCallback() {
    this.innerHTML = /*html*/`
    <div class="modal"></div>`;

    this.classList.add('mask');

    this.modalRoot = this.querySelector('div.modal');

    this.setModal();
  }
}

window.customElements.define('o-modal', HTMLOModal);