import { GraphQl } from "../graphql.mjs";

/**
 * @class QueryMessage
 */
class QueryMessage extends GraphQl {
  prune(message) {
    const fields = [
      'email',
      'topic',
      'platform',
      'subject',
      'message'
    ];
    return this.pruneInput(fields, message);
  }

  async addMessage(message) {
    const result = await this.fetch(`
      mutation addMessage($message: InputMessage!) {
        addMessage(message: $message) {
          message
          code
          is_error
        }
      }
    `, { message: this.prune(message) });

    return result;
  }
}

const queryMessage = new QueryMessage();

export default queryMessage;
