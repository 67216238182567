import { GraphQl } from "../graphql.mjs";

/**
 * @class QueryAd
 */
class QueryAd extends GraphQl {
  prune(ad) {
    const fields = [
      'title',
      'sub_title',
      'short_description',
      'description',
      'link',
      'placement',
      'icon',
      'cover',
      'accent_color',
      'primary_color',
      'is_active'
    ];

    return this.pruneInput(fields, ad);
  }

  async getAd(id) {
    const result = await this.fetch(`
      query getAd($id: ID!) {
        ad(id: $id) {
          _id
          title
          sub_title
          short_description
          description
          link
          placement
          icon
          cover
          accent_color
          primary_color
          is_active
          author {
            _id
            full_name
            display_name
            avatar
            avatar_virtual
          }
          editor {
            _id
            full_name
            display_name
            avatar
            avatar_virtual
          }
          created_at
          updated_at
        }
      }
    `, { id });

    return result;
  }

  async getAds() {
    const result = await this.fetch(`
      query getAds {
        ads {
          _id
          title
          sub_title
          short_description
          description
          link
          placement
          icon
          cover
          accent_color
          primary_color
          is_active
          author {
            _id
            full_name
            display_name
            avatar
            avatar_virtual
          }
          editor {
            _id
            full_name
            display_name
            avatar
            avatar_virtual
          }
          created_at
          updated_at
        }
      }
    `);

    return result;
  }

  async addAd(ad) {
    const result = await this.fetch(`
      mutation addAd($ad: InputAd!) {
        addAd(ad: $ad) {
          _id
          title
          sub_title
          short_description
          description
          link
          placement
          icon
          cover
          accent_color
          primary_color
          is_active
          created_at
          updated_at
        }
      }
    `, { ad: this.prune(ad) });

    return result;
  }

  async removeAd(id) {
    const result = await this.fetch(`
      mutation removeAd($id: ID!) {
        removeAd(id: $id) {
          message
          code
          is_error
        }
      }
    `, { id });

    return result;
  }

  async updateAd(ad) {
    const result = await this.fetch(`
      mutation updateAd($id: ID!, $ad: InputAd!) {
        updateAd(id: $id, ad: $ad) {
          _id
          title
          sub_title
          short_description
          description
          link
          placement
          icon
          cover
          accent_color
          primary_color
          is_active
          author {
            _id
            full_name
            display_name
            avatar
            avatar_virtual
          }
          editor {
            _id
            full_name
            display_name
            avatar
            avatar_virtual
          }
          created_at
          updated_at
        }
      }
    `, { id: ad._id, ad: this.prune(ad) });

    return result;
  }
}

const queryAd = new QueryAd();

export default queryAd;