// eslint-disable-next-line no-undef
export default class HTMLOTextInput extends HTMLElement {
  constructor() {
    super();

    this._value = undefined;
  }

  /**
   * @param {string} value
   */
  set value(value) {
    this._value = value;

    this.renderValue();
  }

  /**
   * @returns {string | undefined}
   */
  get value() {
    return this._value;
  }

  renderValue() {
    this.innerHTML = /*html*/`${this._value ? this._value : ''}`;
  }

  connectedCallback() {
    this.innerHTML = /*html*/`${this.getAttribute('value') ? this.getAttribute('value') : ''}`;
  }
}

window.customElements.define('o-text-input', HTMLOTextInput);