// eslint-disable-next-line no-undef
export default class HTMLONumberInput extends HTMLElement {
  constructor() {
    super();

    this._value = undefined;
  }

  /**
   * @param {string} value
   */
  set value(value) {
    this._value = value;

    this.renderValue();
  }

  /**
   * @returns {string | undefined}
   */
  get value() {
    return this._value;
  }

  renderValue() {
    const unit = this.getAttribute('unit');

    this.innerHTML = /*html*/`
    ${this._value ? this._value : ''}
    <div>${unit ? unit : ''}</div>`;
  }

  connectedCallback() {
    const unit = this.getAttribute('unit');
    const value = this.getAttribute('value');

    this.innerHTML = /*html*/`
    ${value ? value : ''}
    <div>${unit ? unit : ''}</div>`;
  }
}

window.customElements.define('o-number-input', HTMLONumberInput);