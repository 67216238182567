app.directive('discrete.button.async', [
  /**
   *
   */
  () => {
    return {
      scope: {
        label: '@',
        loading: '='
      },
      restrict: 'E',
      replace: true,
      templateUrl: './app.directives/controls/async.html',
      link: () => {}
    };
  }
]);
