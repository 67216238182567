import meta from "../../app.services/route/meta.mjs";

function BlogCtrl() {
  meta.title('Blog');
  // meta.colors([
  //   { min: 0, max: 60, color: undefined },
  //   { min: 60, max: 340, color: 'yellow' },
  //   { min: 340, color: 'light' }
  // ]);
}

BlogCtrl.$inject = [];
BlogCtrl.resolve = {};

app.controller('BlogCtrl', BlogCtrl);
window.BlogCtrl = BlogCtrl;
