import viewText from '../../app.services/core/text.mjs';
import Discrete from '../../app.services/model/discrete.mjs';

app.directive('toggle.discrete', ['$timeout',
  /**
   * @param {import('angular').ITimeoutService} $timeout
   */
  ($timeout) => {
    return {
      scope: {
        prop: '@',
        service: '@',
        get: '@',
        set: '@',
        watch: '@'
      },
      restrict: 'E',
      replace: true,
      templateUrl: './app.directives/controls/toggle.html',
      link: ($scope, element) => {
        const defaultFn = () => {
          $scope.model = false;
        };

        const discrete = new Discrete($scope, defaultFn);

        $timeout(() => {
          element.find('button').addClass('fx-on');
        }, 500);

        $scope.label = viewText.capitalize(viewText.split($scope.prop));

        $scope.toggle = () => {
          $scope.model = !$scope.model;

          discrete.set();
        };

        $scope.$on('$destroy', () => {
          discrete.destroy();
        });
      }
    };
  }
]);