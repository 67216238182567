import angularBridge from '../view/angular-bridge.mjs';
import viewServices from '../view/services.mjs';

/**
 * Connects individual primitive properties to corresponding services
 * that get, set and watch property changes.
 * @class Discrete
 */
export default class Discrete {
  /**
   * @param {import('../../types/angular').OScope} $scope
   * @param {OCallbackFn} [defaultFn]
   * @param {OConfigureFn} [configureFn]
   */
  constructor($scope, defaultFn, configureFn) {
    this.$scope = $scope;
    this.angularBridge = angularBridge;
    this.defaultFn = defaultFn;
    this.configureFn = configureFn;
    this.prop = $scope?.prop;
    this.service = null;
    this.service_name = $scope?.service;
    this.getProp = $scope?.get;
    this.setProp = $scope?.set;
    this.watchProp = $scope?.watch ? $scope.watch : 'watchSelected';
    this.model = null;

    this.getService();
    this.initialize();
  }

  getService() {
    const $injector = this.angularBridge.getInjector();

    if (this.service_name && $injector?.has(this.service_name)) {
      /**
       * Get service from Angular.
       */
      this.service = $injector.get(this.service_name);
    } else if (viewServices.get(this.service_name)) {
      /**
       * Get service from client services.
       */
      this.service = viewServices.get(this.service_name);
    } else {
      throw `Unable to find ${this.service_name} discrete service.`;
    }
  }

  initialize() {
    /**
     * Watch function internalized to contain class scope.
     */
    this.setWatch = () => {
      if (this.service?.[this.getProp]) {
        let prop = this.service[this.getProp](this.prop);

        /**
         * If there is a property set it, otherwise configure default.
         */
        if (prop !== undefined) {
          this.$scope.model = prop;

          if (this.configureFn) {
            this.configureFn(prop, this.model);
          }
        } else if (this.defaultFn) {
          this.defaultFn();
        }

        this.model = prop;
      }
    };

    if (this.service?.[this.watchProp]) {
      this.service[this.watchProp](this.setWatch);
    } else {
      this.setWatch();
    }
  }

  /**
   * Checks to see if the model is different.
   * @param {OProp} new_model
   * @param {OProp} old_model
   * @returns {boolean}
   */
  isDifferent(new_model, old_model) {
    if (new_model !== old_model) {
      return true;
    }

    return false;
  }

  set() {
    const set = () => {
      this.model = this.$scope.model;

      if (this.service?.[this.setProp]) {
        this.service[this.setProp](this.prop, this.model);
      }
    };

    if (this.isDifferent(this.$scope.model, this.model)) {
      set();
    }
  }

  /**
   * Observes all props in the registered service.
   *  @param {OWatchDiscreteFn} fn
   */
  watch(fn) {
    /**
     * Attach watch function to scope for destroy cleanup.
     */
    this.watchAllFn = props => {
      fn(props);
    };

    if (this.service?.watch) {
      this.service.watch(this.watchAllFn);
    }
  }

  /**
   * Cleans up discrete service manager and any additional watch functions.
   */
  destroy() {
    if (this.service?.unwatch) {
      this.service.unwatch(this.setWatch);

      if (this.watchAllFn) {
        this.service.unwatch(this.watchAllFn);
      }
    }
  }
}