// eslint-disable-next-line no-undef
export default class HTMLOSliderInput extends HTMLElement {
  constructor() {
    super();

    /** @type {HTMLElement} */
    this.inputRoot = undefined;
    /** @type {HTMLElement} */
    this.scaleRoot = undefined;

    this._value = undefined;
  }

  /**
   * @param {string} value
   */
  set value(value) {
    this._value = value;

    this.renderValue();
  }

  /**
   * @returns {string | undefined}
   */
  get value() {
    return this._value;
  }

  renderValue() {
    this.inputRoot.innerHTML = /*html*/`
    ${this._value}
    <div>%</div>`;

    this.scaleRoot.style.width = this._value + '%';
  }

  connectedCallback() {
    this.innerHTML = /*html*/`
    <div class="slider">
      <div class="scale"></div>
    </div>
    <div class="input">
      ${this.getAttribute('value')}
      <div>%</div>
    </div>`;

    this.inputRoot = this.querySelector('div.input');
    this.scaleRoot = this.querySelector('div.scale');

    this.value = this.getAttribute('value');
  }
}

window.customElements.define('o-slider-input', HTMLOSliderInput);