/**
 * @class Keyboard
 */
export class Keyboard {
  constructor() {
    this.keys = {};
    this.combo = {
      alt: false,
      cmd: false,
      ctrl: false,
      shift: false
    };

    this.is_suspended = false;

    this.bindWindow();
  }

  bindWindow() {
    window.onkeydown = event => {
      const event_key = event?.key?.toLowerCase();

      if (this.is_suspended && event_key !== 'escape') {
        return;
      }

      let combo_key = '';

      if (event_key === 'alt') {
        this.combo.alt = true;
      } else if (event_key === 'control') {
        this.combo.ctrl = true;
      } else if (event_key === 'meta') {
        this.combo.cmd = true;
      } else if (event_key === 'shift') {
        this.combo.shift = true;
      }

      for (const key in this.combo) {
        if (this.combo[key?.toLowerCase?.()]) {
          combo_key += key?.toLowerCase?.();
        }
      }

      const key = (combo_key) ? this.keys[event_key + combo_key] : this.keys[event_key];

      if (key && key.fn) {
        /**
         * When supporting function is found don't trigger default behavior.
         */
        event.preventDefault();
        event.stopPropagation();
        key.fn();
      }
    };

    window.onkeyup = event => {
      const event_key = event?.key?.toLowerCase();

      if (this.is_suspended && event_key !== 'escape') {
        return;
      }

      /**
       * Cancel combos
       */
      if (event_key === 'alt') {
        this.combo.alt = false;
      } else if (event_key === 'control') {
        this.combo.ctrl = false;
      } else if (event_key === 'meta') {
        this.combo.cmd = false;
      } else if (event_key === 'shift') {
        this.combo.shift = false;
      }
    };
  }

  /**
   * Registers key/combo with trigger function.
   * @param {string} key
   * @param {string} combo
   * @param {() => void} fn
   */
  add(key, combo, fn) {
    if (combo) {
      this.keys[key?.toLowerCase?.() + combo] = { combo, fn };
    } else {
      this.keys[key?.toLowerCase?.()] = { combo, fn };
    }
  }

  suspend() {
    this.is_suspended = true;
  }

  resume() {
    this.is_suspended = false;
  }

  /**
   * Removes key/combo trigger function.
   * @param {string} key
   * @param {string} combo
   */
  remove (key, combo) {
    if (combo) {
      delete this.keys[key?.toLowerCase?.() + combo];
    } else {
      delete this.keys[key?.toLowerCase?.()];
    }
  }

  /**
   * Returns the position of the caret in a content editable element.
   * @param {HTMLElement} node
   * @returns {number}
   */
  getCaretPosition(node) {
    let caret_position = 0;
    let sel = undefined;
    let range = undefined;

    sel = window.getSelection();

    if (sel.rangeCount) {
      range = sel.getRangeAt(0);

      if (range.commonAncestorContainer.parentNode == node) {
        caret_position = range.endOffset;
      }
    }

    return caret_position;
  }

  /**
   * Sets the position of the caret in a content editable element.
   * @param {HTMLElement} node
   * @param {number} position
   */
  setCaretPosition(node, position) {
    const selection = window.getSelection();
    const range = document.createRange();

    range.setStart(node.firstChild, position);
    range.collapse(true);

    selection.removeAllRanges();
    selection.addRange(range);

    node.focus();
  }
}

const keyboard = new Keyboard();

export default keyboard;