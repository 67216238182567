import meta from "../../app.services/route/meta.mjs";

function CompanyCtrl() {
  meta.title('Company');
  // meta.colors([
  //   { min: 0, max: 60, color: undefined },
  //   { min: 60, max: 340, color: 'pink' },
  //   { min: 340, color: 'light' }
  // ]);
}

CompanyCtrl.$inject = [];
CompanyCtrl.resolve = {};

app.controller('CompanyCtrl', CompanyCtrl);
window.CompanyCtrl = CompanyCtrl;
