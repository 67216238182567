export default class HTMLOAccordion extends HTMLElement {
  constructor() {
    super();

    /** @type {HTMLElement} */
    this.aRoot = undefined;
    /** @type {HTMLElement} */
    this.iconRoot = undefined;
    /** @type {HTMLElement} */
    this.sectionRoot = undefined;

    this.heading = this.getAttribute('heading');

    this.is_on = false;

    /**
     * Bind scope.
     */
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    if (this.sectionRoot) {
      this.is_on = !this.is_on;

      if (this.is_on) {
        this.sectionRoot.classList.add('on');
        this.iconRoot.classList.add('fa-minus');
        this.iconRoot.classList.remove('fa-plus');
      } else {
        this.sectionRoot.classList.remove('on');
        this.iconRoot.classList.remove('fa-minus');
        this.iconRoot.classList.add('fa-plus');
      }
    }
  }

  connectedCallback() {
    this.innerHTML = /*html*/`
    <a role="button">
      <i class="far fa-plus"></i>
      <span>${this.heading}</span>
    </a>
    <div>
      ${this.innerHTML}
    </div>`;

    this.aRoot = this.querySelector('a');
    this.iconRoot = this.querySelector('i');
    this.sectionRoot = this.querySelector('div');

    this.aRoot.addEventListener('click', this.toggle);
  }
}

window.customElements.define('o-accordion', HTMLOAccordion);