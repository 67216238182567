import angularBridge from '../view/angular-bridge.mjs';
import viewServices from '../view/services.mjs';
import Observer from '../model/observer.mjs';

class Meta extends Observer {
  constructor() {
    super();

    this.$rootScope = window.app.$rootScope;
    this.prefix = 'Octo';
  }

  /**
   * Updates browser tab title.
   * @param {String} title
   */
  title(title) {
    const meta_title = title ? `${title} - ${this.prefix}` : this.prefix;

    window.document.title = meta_title;

    this.set('title', meta_title);
  }

  /**
   * Updates browser favicon.
   * @param {('NOTIFICATION' | 'NORMAL')} mode
   */
  favicon(mode) {
    /** @type {[HTMLLinkElement]} */
    // @ts-ignore
    const icons = window.document.querySelectorAll("link[rel='icon']");

    if (icons) {
      for (const icon of icons) {
        const size = icon?.sizes?.value;

        if (mode === 'NOTIFICATION') {
          icon.href = `img/icons/octo-notify-${size}.png`;
        }

        if (mode  === 'NORMAL') {
          icon.href = `img/icons/octo-${size}.png`;
        }
      }
    }
  }

  goBack() {
    window.history.back();
  }

  reset() {
    window.location.reload();
  }
}

const meta = new Meta();

viewServices.set('meta', meta);

export default meta;
