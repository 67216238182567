/**
 * Extension that allows for prop observation for value change.
 */
export default class Observer {
  constructor() {
    /**
     * Functions observing the prop.
     * @private
     */
    this.watchFns = [];

    /**
     * Overwritable props object.
     * @type {*}
     */
    this.props = {};
  }

  /**
   * Registers an observer against a property defined by name to watch for next change to the
   * property.
   * @param {OWatchFn} fn
   */
  watch(fn) {
    this.watchFns.push(fn);
  }

  /**
   * Removes an observer from collection of observers.
   * @param {OWatchFn} fn
   */
  unwatch(fn) {
    for (const [index, watchFn] of this.watchFns.entries()) {
      if (watchFn === fn) {
        this.watchFns.splice(index, 1);
      }
    }
  }

  /**
   * Triggers all registered observers with an update.
   */
  trigger() {
    for (const fn of this.watchFns) {
      fn(this.props);
    }
  }

  /**
   * Returns property value by name.
   */
  get () {
    if (this.props) {
      return this.props;
    }
  }

  /**
   * Sets property by name making it available for get and watch.
   * @param {string} name
   * @param {*} prop
   */
  set(name, prop) {
    if (this.props) {
      this.props[name] = prop;

      this.trigger();
    }
  }
}