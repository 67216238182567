import Discrete from "../../app.services/model/discrete.mjs";

app.directive('discrete.description', [
  /**
   */
  () => {
    return {
      scope: {
        prop: '@',
        service: '@',
        get: '@',
        set: '@',
        watch: '@',
        aria: '@',
        chars: '='
      },
      restrict: 'E',
      replace: true,
      templateUrl: './app.directives/controls/description.html',
      link: $scope => {
        const defaultFn = () => {
          $scope.model = null;
        };

        const discrete = new Discrete($scope, defaultFn);

        $scope.max_length = $scope.chars ? $scope.chars : 150;

        $scope.$watch('model', () => {
          discrete.set();
        });

        $scope.$on('$destroy', () => {
          discrete.destroy();
        });
      }
    };
  }
]);
