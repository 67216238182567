import { Store } from '../store.mjs';

/**
 * @class StoreAuth
 */
export class StoreAuth extends Store {
  /**
   * @param {string} token
   */
  login(token) {
    this.localStorage.setItem(`${this.prefix}/auth`, token);
  }

  logout() {
    this.localStorage.removeItem(`${this.prefix}/auth`);
  }

  /**
   * @returns {string | null}
   */
  getToken() {
    return this.localStorage.getItem(`${this.prefix}/auth`);
  }

  addResources(resources) {
    if (resources) {
      this.localStorage.setItem(`${this.prefix}/resources`, this.stringify(resources));
    }
  }

  getResources() {
    const resources = this.localStorage.getItem(`${this.prefix}/resources`);

    return this.parse(resources);
  }

  removeResources() {
    this.localStorage.removeItem(`${this.prefix}/resources`);
  }
}

const storeAuth = new StoreAuth();

export default storeAuth;