import HTMLOAnimation from "../core/animation.mjs";

// eslint-disable-next-line no-undef
export default class HTMLOEditor extends HTMLOAnimation {
  constructor() {
    super();

    this.scale = this.getAttribute('scale');
    this.width = this.getAttribute('width');
    this.height = this.getAttribute('height');

    /** @type {HTMLElement} */
    this.canvasRoot = null;
    /** @type {HTMLElement} */
    this.toolMenuRoot = null;

    /**
     * Bind Scope.
     */
    this.scaleEditor = this.scaleEditor.bind(this);
  }

  /**
   * Adjust the editor scale and size based on a defined scale and parent container.
   */
  scaleEditor() {
    if (this.scale && this.parentElement) {
      const parent_width = this.parentElement.getBoundingClientRect().width;
      const parent_height = this.parentElement.getBoundingClientRect().height;

      this.style.transform = `scale(${this.scale})`;

      this.style.width = Math.ceil(parent_width / parseFloat(this.scale)) + 'px';
      this.style.height = Math.ceil(parent_height / parseFloat(this.scale)) + 'px';
    }
  }

  /**
   * Sets a static size on the editor for panning.
   */
  sizeEditor() {
    if (this.width) {
      this.style.width = this.width + 'px';
    }

    if (this.height) {
      this.style.height = this.height + 'px';
    }
  }

  /**
   * Adjusts the position of the editor to some location.
   * @param {number} x
   * @param {number} y
   */
  position(x, y) {
    this.style.transform = `translate3d(${x}px, ${y}px, 0)`;
  }

  /**
   * Adjusts the position of the canvas to some location.
   */
  panCanvas() {
    if (this.canvasRoot) {
      const x = 100;
      const y = 150;

      this.canvasRoot.style.transform = `translate3d(${x}px, ${y}px, 0)`;
    }
  }

  /**
   *
   * @param {string} menu
   */
  setMenu(menu) {
    if (menu === 'off') {
      this.toolMenuRoot.classList.add('hide');
    } else {
      this.toolMenuRoot.classList.remove('hide');
    }
  }

  /**
   * Renders the menu.
   * @returns {string}
   */
  renderMenu() {
    return /*html*/`
    <div class="menu">
      <o-toolbar-menu-button class="fal fa-folder-open"></o-toolbar-menu-button>
      <hr>
      <div>
        <o-toolbar-menu-button class="fal fa-brackets-curly"></o-toolbar-menu-button>
        <o-toolbar-menu-button class="fal fa-bolt"></o-toolbar-menu-button>
        <o-toolbar-menu-button on class="fal fa-pencil-ruler"></o-toolbar-menu-button>
        <o-toolbar-menu-button class="fal fa-comment-alt"></o-toolbar-menu-button>
      </div>
      <div>
        <o-toolbar-menu-button on class="fal fa-vector-square"></o-toolbar-menu-button>
        <o-toolbar-menu-button class="fal fa-code"></o-toolbar-menu-button>
        <o-toolbar-menu-button class="fal fa-chart-network"></o-toolbar-menu-button>
        <o-toolbar-menu-button on class="fal fa-layer-group"></o-toolbar-menu-button>
        <o-toolbar-menu-button class="fal fa-badge-check"></o-toolbar-menu-button>
        <o-toolbar-menu-button class="fal fa-phone-laptop"></o-toolbar-menu-button>
      </div>
      <div>
        <o-toolbar-menu-button class="fal fa-sliders-h-square"></o-toolbar-menu-button>
        <o-toolbar-menu-button class="fal fa-keyboard"></o-toolbar-menu-button>
      </div>
    </div>`;
  }

  /**
   * Renders the canvas design.
   */
  renderCanvas() {
    return /*html*/`
    <o-blog-design
      id="design-1"
      label="iPad"
      width="400"
      height="600" selected></o-blog-design>
    <o-blog-design
      id="design-2"
      label="iPad"
      width="400"
      height="600"
      x="450"></o-blog-design>
    <o-blog-design
      id="design-3"
      label="iPad"
      width="400"
      height="600"
      x="900"></o-blog-design>`;
  }

  connectedCallback() {
    this.innerHTML = /*html*/`
    <div class="design">
      <div class="ruler">
        <div class="ruler-x"></div>
        <div class="ruler-y"></div>
      </div>
      <div class="guides">
        <div class="elements-button">
          <div></div>
        </div>
        <div>
          <div class="zoom-button fal fa-plus"></div>
          <div class="far fa-search"></div>
          100%
          <div class="zoom-button fal fa-minus"></div>
        </div>
      </div>
      <div class="canvas">
        ${this.renderCanvas()}
      </div>
      <div class="breadcrumbs"></div>
    </div>
    <div class="tool-menu">
      <div class="handle"></div>
      ${this.renderMenu()}
      <div class="tools">
        <div class="header">
          <o-users-presence></o-users-presence>
          <o-design-version></o-design-version>
        </div>
        <o-toolbar toolbar="page"></o-toolbar>
      </div>
      <o-layers></o-layers>
    </div>
    <o-modal></o-modal>
    <o-cursor id="user-1" name="John" color="blue"></o-cursor>
    <o-cursor id="user-2" name="Paul" color="green"></o-cursor>
    <o-cursor id="user-3" name="Paul" color="orange"></o-cursor>
    <o-cursor id="user-4" name="Paul" color="purple"></o-cursor>`;

    this.canvasRoot = this.querySelector('div.canvas');
    this.toolMenuRoot = this.querySelector('div.tool-menu');

    window.addEventListener('resize', this.scaleEditor);
    this.parentElement.addEventListener('rendered', this.scaleEditor);

    this.configureElements();
    this.scaleEditor();
    this.sizeEditor();
    this.panCanvas();

    this.startAnimation();
  }
}

window.customElements.define('o-editor', HTMLOEditor);