import ManagerBase  from './manager-base.mjs';
import queryPost from '../api/queries/post.mjs';
import viewServices from '../view/services.mjs';
import angularBridge from '../view/angular-bridge.mjs';

class ManageBlog extends ManagerBase  {
  constructor() {
    super();

    this.queryPost = queryPost;
    this.angularBridge = angularBridge;
  }

  async getPosts() {
    this.props = {
      sort: 'name',
      reverse: false,
      layout: false
    };
    this.props.raw = await this.queryPost.getPosts();

    this.filter();
  }

  filter() {
    const orderByFilter = this.angularBridge.getOrderByFilter();

    this.props.posts = orderByFilter(
      this.props.raw,
      this.props.sort,
      this.props.reverse
    );

    this.trigger();
  }
}

const manageBlog = new ManageBlog();

export default manageBlog;

viewServices.set('manageBlog', manageBlog);