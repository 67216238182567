import meta from "../../app.services/route/meta.mjs";

function WindowsCtrl() {
  meta.title('Download Windows');
  // meta.colors([
  //   { min: 0, max: 60, color: undefined },
  //   { min: 60, max: 500, color: 'blue' },
  //   { min: 500, color: 'light' }
  // ]);
}

WindowsCtrl.$inject = [];
WindowsCtrl.resolve = {};

app.controller('WindowsCtrl', WindowsCtrl);
window.WindowsCtrl = WindowsCtrl;
