import Scroll from "../core/scroll.mjs";

export default class HTMLOHero extends Scroll {
  constructor() {
    super();

    /**
     * Name of the background color.
     * @type {string}
     */
    this.color = undefined;
  }

  connectedCallback() {
    if (this.hasAttribute('scroll')) {
      this.watchScroll(this.getAttribute('scroll'));
    }

    if (this.hasAttribute('color')) {
      this.color = this.getAttribute('color');

      this.classList.add(this.color);
    }
  }

  disconnectedCallback() {
    this.unwatchScroll();
  }
}

window.customElements.define('o-hero', HTMLOHero);