import { GraphQl } from "../graphql.mjs";

/**
 * @class QueryUpdate
 */
class QueryUpdate extends GraphQl {
  async getUpdate(id) {
    const result = await this.fetch(`
      query getUpdate($id: ID!) {
        update(id: $id) {
          _id
          title
          version
          description
          cover
          cover_primary_color
          is_active
          author {
            _id
            full_name
            display_name
            avatar
            avatar_virtual
          }
          editor {
            _id
            full_name
            display_name
            avatar
            avatar_virtual
          }
          created_at
          updated_at
          released_at
        }
      }
    `, { id });

    return result;
  }

  async getUpdates() {
    const result = await this.fetch(`
      query getUpdates {
        updates {
          _id
          title
          version
          description
          cover
          cover_primary_color
          is_active
          author {
            _id
            full_name
            display_name
            avatar
            avatar_virtual
          }
          editor {
            _id
            full_name
            display_name
            avatar
            avatar_virtual
          }
          created_at
          updated_at
          released_at
        }
      }
    `);

    return result;
  }
}

const queryUpdate = new QueryUpdate();

export default queryUpdate;
