// eslint-disable-next-line no-undef
export default class HTMLOAvatar extends HTMLElement {
  constructor() {
    super();
  }


  connectedCallback() {

  }
}

window.customElements.define('o-avatar', HTMLOAvatar);