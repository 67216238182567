import meta from "../../app.services/route/meta.mjs";

function GuidesCtrl() {
  meta.title('Guides');
}

GuidesCtrl.$inject = [];
GuidesCtrl.resolve = {};

app.controller('GuidesCtrl', GuidesCtrl);
window.GuidesCtrl = GuidesCtrl;
