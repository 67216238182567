import { GraphQl } from "../graphql.mjs";

/**
 * @class QueryBilling
 */
class QueryBilling extends GraphQl {
  async getPlans() {
    const result = await this.fetch(`
      query getPlans {
        plans {
          _id
          name
          short_description
          description
          order
          features {
            name
            is_available
            short_description
            description
          }
          prices {
            id
            active
            currency
            type
            unit_amount
            unit_amount_decimal
            recurring {
              interval
              interval_count
            }
          }
          accent_color
          primary_color
          font_color
          is_active
        }
      }
    `);

    return result;
  }
}

const queryBilling = new QueryBilling();

export default queryBilling;