import meta from "../../app.services/route/meta.mjs";

function AndroidCtrl() {
  meta.title('Download Android');
  // meta.colors([
  //   { min: 0, max: 60, color: undefined },
  //   { min: 60, max: 500, color: 'yellow' },
  //   { min: 500, color: 'light' }
  // ]);
}

AndroidCtrl.$inject = [];
AndroidCtrl.resolve = {};

app.controller('AndroidCtrl', AndroidCtrl);
window.AndroidCtrl = AndroidCtrl;
