import manageBilling from "../../app.services/managers/billing.mjs";

app.directive('pricing.plans', [
  /**
   */
  () => {
    return {
      scope: {
        interval: '@'
      },
      restrict: 'E',
      replace: true,
      templateUrl: './app.directives/pricing/plans.html',
      link: $scope => {
        $scope.display = () => {
          if ($scope.plans?.length === 1) {
            return 'one';
          }

          if ($scope.plans?.length === 2) {
            return 'two';
          }

          return 'three';
        };

        $scope.getPath = (plan, interval) => {
          const path = 'main.account.billing-checkout';
          const link = `${window.api.editor_domain}?path=${path}&plan=${plan}&interval=${interval}`;

          return encodeURI(link);
        };

        const watchPlans = props => {
          $scope.$applyAsync(() => {
            if (props.plans) {
              $scope.plans = props.plans.filter(plan => plan.is_active);
            }
          });
        };

        manageBilling.watch(watchPlans);
        manageBilling.getPlans();

        $scope.$on('$destroy', () =>  {
          manageBilling.unwatch(watchPlans);
        });
      }
    };
  }
]);
