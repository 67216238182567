export class AngularBridge {
  constructor() {
    this.document = window.angular?.element(document);
    this.app = window.app;
  }

  get(name) {
    if (this.document?.injector) {
      const injector = this.document.injector();

      if (injector) {
        return injector.get(name);
      }
    }
  }

  /**
   * @returns
   */
  getTransitions() {
    if (this.document?.injector) {
      const injector = this.document.injector();

      if (injector) {
        return injector.get('$transitions');
      }
    }
  }

  /**
   * @returns {import('angular').IRootScopeService}
   */
  getRootScope() {
    if (this.document?.injector) {
      const injector = this.document.injector();

      if (injector) {
        return injector.get('$rootScope');
      }
    }
  }

  /**
   * @returns {import('angular').IFilterOrderBy}
   */
  getOrderByFilter() {
    if (this.document?.injector) {
      const injector = this.document.injector();

      if (injector) {
        return injector.get('orderByFilter');
      }
    }
  }

  /**
   */
  getState() {
    if (this.document?.injector) {
      const injector = this.document.injector();

      if (injector) {
        return injector.get('$state');
      }
    }
  }

  /**
   * @returns {import('angular').ICompileService}
   */
  getCompile() {
    if (this.document?.injector) {
      const injector = this.document.injector();

      if (injector) {
        return injector.get('$compile');
      }
    }
  }

  /**
   * @returns {import('angular').ISCEService}
   */
  getSce() {
    if (this.document?.injector) {
      const injector = this.document.injector();

      if (injector) {
        return injector.get('$sce');
      }
    }
  }

  getInjector() {
    if (this.document?.injector) {
      const injector = this.document.injector();

      if (injector) {
        return injector;
      }
    }
  }

  /**
   * Registers a factory in angular.
   * @param {string} name
   * @param {object} collection
   */
  factory(name, collection) {
    this.app?.factory(name, collection);
  }

  /**
   * Registers a service in angular.
   * @param {string} name
   * @param {object} collection
   */
  service(name, collection) {
    this.app?.service(name, collection);
  }

  /**
   * @param {string} element
   * @returns {JQLite}
   */
  element(element) {
    return window.angular?.element(element);
  }
}

export default new AngularBridge();
