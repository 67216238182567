/**
 * Supporting number manipulations.
 * @class Numbers
 */
export class Numbers {
  /**
   * Checks to see if an angle is a right angle.
   * @param {number} angle
   */
  isRightAngle(angle) {
    /**
     * Normalize the angle to be between -360 and 360 degrees.
     */
    angle = angle % 360;

    /**
     * Check if the absolute value of the angle is a multiple of 90 degrees.
     */
    return Math.abs(angle) % 90 === 0;
  }

  /**
   * @param {number} number
   */
  isPositive(number) {
    if (Math.sign(number) === 1 || Math.sign(number) === 0) {
      return true;
    }

    return false;
  }

  /**
   * @param {*} number
   * @return {boolean}
   */
  isNumber(number) {
    if (typeof number === 'number' && !isNaN(number)) {
      return true;
    }

    return false;
  }

  /**
   * Converts a string into a number.
   * @param {number | string} text
   * @returns {number}
   */
  toNumber(text) {
    if (typeof text === 'string') {
      const parsed = parseFloat(text);

      if (!isNaN(parsed)) {
        return parsed;
      }

      return 0;
    }

    if (typeof text === 'number') {
      if (isNaN(text)) {
        return 0;
      }

      return text;
    }

    return 0;
  }

  /**
   * @param {number} num_1
   * @param {number} num_2
   */
  getSmaller(num_1, num_2) {
    return num_1 < num_2 ? num_1 : num_2;
  }

  /**
   * @param {number} num_1
   * @param {number} num_2
   */
  getBigger(num_1, num_2) {
    return num_1 > num_2 ? num_1 : num_2;
  }

  /**
   * Finds the hypothenuse of a coordinate.
   * @param {number} x
   * @param {number} y
   * @returns {number | undefined}
   */
  findHypothenuse(x, y) {
    if (typeof x === 'number' && typeof y === 'number') {
      return Math.sqrt(Math.pow(y, 2) + Math.pow(x, 2));
    }
  }

  /**
   * Finds the midpoint between 2 linear coordinates.
   * @param {number} n_1
   * @param {number} n_2
   * @returns {number | undefined}
   */
  findMid(n_1, n_2) {
    if (typeof n_1 === 'number' && typeof n_2 === 'number') {
      return (n_1 + n_2) / 2;
    }
  }

  getDelta(start_1, start_2, end_1, end_2) {
    const start = this.findMid(start_1, start_2);
    const end = this.findMid(end_1, end_2);

    return start - end;
  }
}

export default new Numbers();
