// eslint-disable-next-line no-undef
export default class HTMLOLayers extends HTMLElement {
  constructor() {
    super();
  }

  isOn(is_on) {
    if (is_on) {
      this.style.display = 'grid';
    } else {
      this.style.display = 'none';
    }
  }

  /**
   * Renders layer node for layers.
   * @param {string} label
   * @param {string} icon
   * @param {*} [children]
   * @returns {string}
   */
  renderLayerNode(label, icon, children) {
    const children_nodes = children ? children : '';

    return /*html*/`
    <div class="layer-node">
      <div class="item">
        <div class="caret fa fa-caret-down"></div>
        <div class="fas ${icon}"></div>
        <div class="label">${label}</div>
        ${children_nodes}
      </div>
    </div>`;
  }

  /**
   * Builds layers based on parent element.
   * @param {HTMLElement} parent
   * @returns {string}
   */
  buildLayers(parent) {
    const layers = '';

    if (parent?.children) {
      const parent = this.renderLayerNode('Page', 'fas fa-browser');

      // for (const child of parent.children) {

      // }
    }

    return layers;
  }

  /**
   * Renders the layers in the menu.
   * @returns {string}
   */
  renderLayers() {
    return /*html*/`
    <div class="layers">
      ${this.renderLayerNode('Page', 'fas fa-browser')}
      <div class="layer-node on">
        <div class="item">
          <div class="caret fa fa-caret-down"></div>
          <div class="fas fa-browser"></div>
          <div class="label">Page</div>
        </div>
        <div class="layer-node">
          <div class="item">
            <div class="caret fa fa-caret-down"></div>
            <div class="fas fa-browser"></div>
            <div class="label">div</div>
          </div>
        </div>
      </div>
      <div class="layer-node">
        <div class="item">
          <div class="caret fa fa-caret-down"></div>
          <div class="fas fa-browser"></div>
          <div class="label">Page</div>
        </div>
      </div>
      <div class="layer-node">
        <div class="item">
          <div class="caret fa fa-caret-down"></div>
          <div class="fas fa-browser"></div>
          <div class="label">Page</div>
        </div>
      </div>
    </div>`;
  }

  connectedCallback() {
    this.innerHTML = /*html*/`
    <div class="header">
      <div>Layers</div>
      <div class="fal fa-minus-square"></div>
      <div class="fal fa-times"></div>
    </div>
    ${this.renderLayers()}`;

    const is_on = this.hasAttribute('on');

    this.isOn(is_on);
  }
}

window.customElements.define('o-layers', HTMLOLayers);