/**
 * @class Store
 */
export class Store {
  constructor() {
    this.prefix = 'octo';
    this.project_id = null;
    this.localStorage = localStorage;
  }

  /**
   * @param {string} item
   * @returns {object}
   */
  parseEmpty(item) {
    const empty = { error: 'empty' }

    try {
      return item ? JSON.parse(item) : empty;
    } catch(_) {
      return empty;
    }
  }

  /**
   * @param {string} item
   * @returns {object}
   */
  parse(item) {
    try {
      return item ? JSON.parse(item) : {};
    } catch(_) {
      return {};
    }
  }

  /**
   * @param {string} item
   * @returns {Array<*>}
   */
  parseArray(item) {
    try {
      return item ? JSON.parse(item) : [];
    } catch(_) {
      return [];
    }
  }

  /**
   * @param {object | Array} item
   * @returns {string}
   */
  stringify(item) {
    try {
      return item ? JSON.stringify(item) : '';
    } catch(_) {
      return '';
    }
  }

  /**
   * @param {string} path
   */
  savePath(path) {
    this.localStorage.setItem(`${this.prefix}/path`, path);
  }

  /**
   * @returns {string}
   */
  getPath() {
    return this.localStorage.getItem(`${this.prefix}/path`);
  }

  /**
   * @param {OSettings} settings
   */
  saveSettings(settings) {
    if (settings) {
      const raw_user = this.localStorage.getItem(`${this.prefix}/user`);
      const user = this.parse(raw_user);
      const settings_string = this.stringify(settings);

      this.localStorage.setItem(`${this.prefix}/${user?._id}/settings`, settings_string);
    }
  }

  getSettings() {
    const raw_user = this.localStorage.getItem(`${this.prefix}/user`);
    const user = this.parse(raw_user);

    let settings = this.localStorage.getItem(`${this.prefix}/${user?._id}/settings`);

    return this.parse(settings);
  }

  resetSettings() {
    const raw_user = this.localStorage.getItem(`${this.prefix}/user`);
    const user = this.parse(raw_user);

    this.localStorage.removeItem(`${this.prefix}/${user?._id}/settings`);
  }

  saveTools(page) {
    if (page) {
      page = this.stringify(page);

      this.localStorage.setItem(`${this.prefix}/page`, page);
    }
  }

  /**
   * @returns {object}
   */
  getTools() {
    let page = this.localStorage.getItem(`${this.prefix}/page`);

    return this.parse(page);
  }

  /**
   * Updates the list of read notification by id.
   * @param {string} id
   */
  updateNotifications(id) {
    if (id) {
      let notifications = this.localStorage.getItem(`${this.prefix}/notifications`);
      let ids = this.parseArray(notifications);

      ids.push(id);

      const ids_string = this.stringify(ids);

      this.localStorage.setItem(`${this.prefix}/notifications`, ids_string);
    }
  }

  /**
   * Returns a list of read notifications.
   */
  getNotifications() {
    const notifications = this.localStorage.getItem(`${this.prefix}/notifications`);

    return this.parseArray(notifications);
  }

  /**
   * @param {string} key
   * @param {string} combo
   * @param {string} id
   */
  setShortcut(key, combo, id) {
    const raw_user = this.localStorage.getItem(`${this.prefix}/user`);
    const user = this.parse(raw_user);

    const shortcuts = this.localStorage.getItem(`${this.prefix}/${user?._id}/shortcuts`);
    const parsed = this.parse(shortcuts);

    parsed[id] = { key, combo, id };

    const stringify = this.stringify(parsed);

    this.localStorage.setItem(`${this.prefix}/${user?._id}/shortcuts`, stringify);
  }

  /**
   * Returns all shortcut key settings.
   * @param {string} id
   */
  getShortcut(id) {
    const raw_user = this.localStorage.getItem(`${this.prefix}/user`);
    const user = this.parse(raw_user);

    const shortcuts = this.localStorage.getItem(`${this.prefix}/${user?._id}/shortcuts`);
    const parsed = this.parse(shortcuts);

    return parsed[id];
  }

  resetShortcuts() {
    const raw_user = this.localStorage.getItem(`${this.prefix}/user`);
    const user = this.parse(raw_user);

    this.localStorage.removeItem(`${this.prefix}/${user?._id}/shortcuts`);
  }
}

const store = new Store();

export default store;