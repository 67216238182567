import ManagerBase  from './manager-base.mjs';
import queryMessage from '../api/queries/message.mjs';
import viewServices from '../view/services.mjs';

class ManageMessage extends ManagerBase  {
  constructor() {
    super();

    this.queryMessage = queryMessage;
  }

  async send() {
    const result = await this.queryMessage.addMessage(this.props);

    this.clear();
    this.trigger();

    return result;
  }
}

const manageMessage = new ManageMessage();

export default manageMessage;

viewServices.set('manageMessage', manageMessage);