export default class HTMLOFigure extends HTMLElement {
  constructor() {
    super();

    this.aspect = this.getAttribute('aspect');
    this.frame = this.getAttribute('frame');
    this.has_limit = this.hasAttribute('limit');

    /** @type {HTMLElement} */
    this.figure = undefined;
  }

  renderBlank() {
    return /*html*/`
    <div class="figure">
      ${this.innerHTML}
    </div>`;
  }

  /**
   * @returns {string}
   */
  renderBrowser() {
    return /*html*/`
    <div class="header">
      <div class="menu">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
      <div class="search"></div>
    </div>
    <div class="figure">
      ${this.innerHTML}
    </div>`;
  }

  /**
   * @returns {string}
   */
  renderIPad() {
    return /*html*/`
    <div class="volume"></div>
    <div class="figure">
      ${this.innerHTML}
    </div>`;
  }

  /**
   * @returns {string}
   */
  renderMacOs() {
    return /*html*/`
    <div class="header">
      <div class="menu">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
      <div class="navigation"></div>
    </div>
    <div class="figure">
      ${this.innerHTML}
    </div>`;
  }

  /**
   * @returns {string}
   */
  renderWindows() {
    return /*html*/`
    <div class="header">
      <div class="navigation"></div>
      <div class="menu">
        <div class="fal fa-minus"></div>
        <div class="fal fa-square"></div>
        <div class="fal fa-times"></div>
      </div>
    </div>
    <div class="figure">
      ${this.innerHTML}
    </div>`;
  }

  /**
   * @returns {string}
   */
  renderAndroid() {
    return /*html*/`
    <div class="figure">
      ${this.innerHTML}
    </div>`;
  }

  connectedCallback() {
    if (this.frame === 'blank') {
      this.innerHTML = this.renderBlank();
    }

    if (this.frame === 'browser') {
      this.innerHTML = this.renderBrowser();
    }

    if (this.frame === 'ipad') {
      this.innerHTML = this.renderIPad();
    }

    if (this.frame === 'macos') {
      this.innerHTML = this.renderMacOs();
    }

    if (this.frame === 'windows') {
      this.innerHTML = this.renderIPad();
    }

    if (this.frame === 'android') {
      this.innerHTML = this.renderAndroid();
    }

    this.classList.add(this.frame);
    this.classList.add(this.aspect);

    if (this.has_limit) {
      this.classList.add('limit');
    }

    this.figure = this.querySelector('div.figure');
    this.figure.dispatchEvent(new CustomEvent('rendered', { bubbles: true, cancelable: true }));
  }
}

window.customElements.define('o-figure', HTMLOFigure);