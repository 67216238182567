import meta from "../../app.services/route/meta.mjs";

function HomeCtrl($scope, $state, $anchorScroll) {
  meta.title('');
  // meta.colors([
  //   { min: 0, max: 60, color: undefined },
  //   { min: 60, max: 800, color: 'blue' },
  //   { min: 800, max: 3120, color: 'light' },
  //   { min: 3120, max: 3720, color: 'dark-blue' },
  //   { min: 3720, max: 5000, color: 'light' },
  //   { min: 5000, color: 'dark-blue' },
  // ]);

  $scope.to = (path) => {
    $state.go(path);

    $anchorScroll();
  };
}

HomeCtrl.$inject = ['$scope', '$state', '$anchorScroll'];
HomeCtrl.resolve = {};

app.controller('HomeCtrl', HomeCtrl);
window.HomeCtrl = HomeCtrl;
