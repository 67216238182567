/**
 * @class CommonEvent
 */
class CommonEvent {
  /**
   * For preventing a function from triggering for amount of time.
   * @param {function} callback
   * @param {Number} wait
   */
  debounce(callback, wait) {
    let timeout;

    return (...args) => {
      const context = this;

      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
    };
  }
}

app.service('commonEvent', [CommonEvent]);
