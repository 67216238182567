import HTMLOAnimation from "../core/animation.mjs";

// eslint-disable-next-line no-undef
export default class HTMLOProjects extends HTMLOAnimation {
  constructor() {
    super();

    this.scale = this.getAttribute('scale');
    this.width = this.getAttribute('width');
    this.height = this.getAttribute('height');

    /**
     * Bind Scope.
     */
    this.scaleProjects = this.scaleProjects.bind(this);
  }

  /**
   * Adjust the editor scale and size based on a defined scale and parent container.
   */
  scaleProjects() {
    if (this.scale && this.parentElement) {
      const parent_width = this.parentElement.getBoundingClientRect().width;
      const parent_height = this.parentElement.getBoundingClientRect().height;

      this.style.transform = `scale(${this.scale})`;

      this.style.width = Math.ceil(parent_width / parseFloat(this.scale)) + 'px';
      this.style.height = Math.ceil(parent_height / parseFloat(this.scale)) + 'px';
    }
  }

  /**
   * Sets a static size on the editor for panning.
   */
  sizeProjects() {
    if (this.width) {
      this.style.width = this.width + 'px';
    }

    if (this.height) {
      this.style.height = this.height + 'px';
    }
  }

  /**
   * Adjusts the position of the editor to some location.
   * @param {number} x
   * @param {number} y
   */
  position(x, y) {
    this.style.transform = `translate3d(${x}px, ${y}px, 0)`;
  }

  /**
   * Renders the navigation menu.
   * @returns {string}
   */
  renderNavigationMenu() {
    return /*html*/`
    <div class="main-menu">
      <div class="user">
        <div class="user-avatar">
          <o-image path="https://s3.amazonaws.com/octo.coffee/marketing/9bc55efe-b7f0-4270-bef6-558ec1f5795f.jpeg%3Fcolor%3D%236e6b6e"></o-image>
          <div class="presence"></div>
        </div>
      </div>
      <hr>
      <o-main-menu-button class="fal fa-bell"></o-main-menu-button>
      <o-main-menu-button on class="fal fa-folder-open"></o-main-menu-button>
      <o-main-menu-button class="fal fa-book"></o-main-menu-button>
      <o-main-menu-button class="fal fa-sliders-h-square"></o-main-menu-button>
      <o-main-menu-button class="fal fa-keyboard"></o-main-menu-button>
    </div>
    <div class="sub-menu">
      <div class="title">Projects</div>
      <o-main-sub-menu-button on>Recent</o-main-sub-menu-button>
      <o-main-sub-menu-button>Archived</o-main-sub-menu-button>
      <o-main-sub-menu-button>Library</o-main-sub-menu-button>
      <hr>
      <div class="sub-title">Organizations</div>
      <div class="organization">
        <div>
          <o-image path="https://s3.amazonaws.com/octo.coffee/marketing/9bc55efe-b7f0-4270-bef6-558ec1f5795f.jpeg%3Fcolor%3D%236e6b6e"></o-image>
        </div>
        National Observatory
      </div>
      <div class="organization">
        <div class="green">S</div>
        Space Podcast
      </div>
    </div>`;
  }

  connectedCallback() {
    this.innerHTML = /*html*/`
    <div class="navigation-menu">
      ${this.renderNavigationMenu()}
    </div>
    <div class="content">
      <o-projects-list></o-projects-list>
    </div>
    <o-drawer></o-drawer>
    <o-modal></o-modal>
    <o-cursor id="user-1" name="John" color="blue"></o-cursor>
    <o-cursor id="user-2" name="Paul" color="green"></o-cursor>
    <o-cursor id="user-3" name="Paul" color="orange"></o-cursor>
    <o-cursor id="user-4" name="Paul" color="purple"></o-cursor>`;

    window.addEventListener('resize', this.scaleProjects);
    this.parentElement.addEventListener('rendered', this.scaleProjects);

    this.configureElements();
    this.scaleProjects();
    this.sizeProjects();
    this.startAnimation();
  }
}

window.customElements.define('o-projects', HTMLOProjects);