export default class HTMLOHelper extends HTMLElement {
  constructor() {
    super();

    /**
     * Bind scope.
     */
    this.scroll = this.scroll.bind(this);
  }

  scroll() {
    window.scrollTo({top: 0, behavior: 'smooth' });
  }

  connectedCallback() {
    this.innerHTML = /*html*/`<i class="fa fa-chevron-up"></i>`;

    this.addEventListener('click', this.scroll);
  }
}

window.customElements.define('o-helper', HTMLOHelper);