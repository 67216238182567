// eslint-disable-next-line no-undef
export default class HTMLOToolbar extends HTMLElement {
  constructor() {
    super();

    /** @type {HTMLElement} */
    this.unselectedRoot = undefined;
    /** @type {HTMLElement} */
    this.pageRoot = undefined;
    /** @type {HTMLElement} */
    this.elementRoot = undefined;
  }

  renderUnselectedProperties() {
    return /*html*/`
    <div id="toolbar-unselected" class="toolbar empty">
      <div class="fal fa-pencil-ruler"></div>
      <div class="label">Click on any element to edit properties.</div>
    </div>`;
  }

  /**
   * Renders the toolbar needed to make a page in the menu.
   * @returns {string}
   */
  renderPageProperties() {
    return /*html*/`
    <div id="toolbar-page" class="toolbar">
      <div class="field">
        <div class="label">Name</div>
        <o-text-input value="Page"></o-text-input>
      </div>
      <div class="field">
        <div class="label">Active</div>
        <o-toggle-button on="true"></o-toggle-button>
      </div>
      <hr>
      <div class="field">
        <div class="label">Position X</div>
        <o-number-input value="0" unit="X"></o-number-input>
      </div>
      <div class="field">
        <div class="label">Position Y</div>
        <o-number-input value="0" unit="Y"></o-number-input>
      </div>
      <div class="field">
        <div class="label">Width</div>
        <o-number-input id="width" value="400" unit="px"></o-number-input>
      </div>
      <div class="field">
        <div class="label">Height</div>
        <o-number-input value="400" unit="px"></o-number-input>
      </div>
      <div class="field">
        <div class="label">Overflow</div>
        <o-dropdown-input value="Hide"></o-dropdown-input>
      </div>
      <div class="header">Background</div>
      <div class="field three">
        <o-checkbox-button on="true"></o-checkbox-button>
        <o-color-input value="#ffffff"></o-color-input>
        <o-slider-input value="100"></o-slider-input>
      </div>
    </div>`;
  }

  /**
   * Renders the toolbar needed to make a div in the menu.
   * @returns {string}
   */
  renderElementProperties() {
    return /*html*/`
    <div id="toolbar-element" class="toolbar">
      <div class="field">
        <div class="label">Name</div>
        <o-text-input value="div"></o-text-input>
      </div>
      <div class="field">
        <div class="label">Active</div>
        <o-toggle-button on="true"></o-toggle-button>
      </div>
      <hr>
      <div class="field">
        <div class="label">Width</div>
        <o-number-input value="400" unit="px"></o-number-input>
      </div>
      <div class="field">
        <div class="label">Height</div>
        <o-number-input value="400" unit="px"></o-number-input>
      </div>
      <div class="field">
        <div class="label">Radius</div>
        <o-slider-input value="0"></o-slider-input>
      </div>
      <div class="field">
        <div class="label">Opacity</div>
        <o-slider-input value="100"></o-slider-input>
      </div>
      <div class="field">
        <div class="label">Rotation</div>
        <o-slider-input value="0"></o-slider-input>
      </div>
      <div class="header">Position</div>
      <div class="field">
        <div class="label">Position</div>
        <o-dropdown-input></o-dropdown-input>
      </div>
      <div class="field">
        <div class="label">Z Index</div>
        <o-number-input></o-number-input>
      </div>
    </div>`;
  }

  /**
   * Sets and updates the toolbar.
   * @param {string} toolbar
   */
  setMenu(toolbar) {
    this.pageRoot.style.display = 'none';
    this.elementRoot.style.display = 'none';
    this.unselectedRoot.style.display = 'none';

    if (toolbar === 'page') {
      this.pageRoot.style.display = 'grid';

      return;
    }

    if (toolbar === 'element') {
      this.elementRoot.style.display = 'grid';

      return;
    }

    this.unselectedRoot.style.display = 'flex';
  }

  connectedCallback() {
    this.innerHTML = /*html*/`
    ${this.renderUnselectedProperties()}
    ${this.renderPageProperties()}
    ${this.renderElementProperties()}
    `;

    this.unselectedRoot = this.querySelector('#toolbar-unselected');
    this.pageRoot = this.querySelector('#toolbar-page');
    this.elementRoot = this.querySelector('#toolbar-element');

    const toolbar = this.getAttribute('toolbar');

    this.setMenu(toolbar);
  }
}

window.customElements.define('o-toolbar', HTMLOToolbar);