export default class HTMLOGreeblie extends HTMLElement {
  constructor() {
    super();
  }

  renderGreeblieAbove() {
    return /*html*/ `
    <svg
      class="greeblie ${this.size} ${this.side} invert"
      viewBox="0 0 400 400"
      xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="0%" y1="0%" x2="100%" y2="0%" id="green">
          <stop offset="0%" stop-color="${this.color_1}" />
          <stop offset="100%" stop-color="${this.color_2}" />
        </linearGradient>
      </defs>
      <path d="M400,400H0c82.2,0,120.7-84,200-84S297.5,400,400,400z" fill="url(#green)"/>
    </svg>`;
  }

  renderGreeblieBelow() {
    return /*html*/ `
    <svg
      class="greeblie ${this.size} ${this.side} dark below"
      viewBox="0 0 400 400"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M400,400H0c82.2,0,120.7-84,200-84S297.5,400,400,400z"/>
    </svg>`
  }

  renderGreeblie() {
    return /*html*/ `
    <svg
      class="greeblie ${this.size} ${this.side}"
      viewBox="0 0 400 400"
      xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="0%" y1="0%" x2="0%" y2="100%" id="yellow">
          <stop offset="0%" stop-color="${this.color_1}" />
          <stop offset="100%" stop-color="${this.color_2}" />
        </linearGradient>
      </defs>
      <path d="M400,400H0c82.2,0,120.7-84,200-84S297.5,400,400,400z" fill="url(#yellow)"/>
    </svg>`;
  }

  connectedCallback() {
    const position = this.getAttribute('position');

    this.color_1 = this.getAttribute('color-1');
    this.color_2 = this.getAttribute('color-2');
    this.size = this.getAttribute('size') ? this.getAttribute('size') : 'very-large';
    this.side = this.getAttribute('side') ? this.getAttribute('side') : 'left';

    if (position === 'above') {
      this.innerHTML = this.renderGreeblieAbove();
    } else if (position === 'below') {
      this.innerHTML = this.renderGreeblieBelow();
    } else {
      this.innerHTML = this.renderGreeblie();
    }
  }
}

window.customElements.define('o-greeblie', HTMLOGreeblie);