import Observer from "../model/observer.mjs";

/**
 * Service manager from DOM scrolling.
 */
export class ViewScroll extends Observer {
  constructor() {
    super();

    /**
     * @type {OScrollProps}
     */
    this.props = {};
  }

  /**
   * @param {OScrollProps} props
   */
  paintOn(props) {
    this.props = props;

    this.trigger();
  }

  /**
   * @param {OScrollProps} props
   */
  paintOff(props) {
    this.props = props;

    this.trigger();
  }
}

export default new ViewScroll();