export default class HTMLOImage extends HTMLElement {
  constructor() {
    super();

    /** @type {HTMLImageElement} */
    this.imgRoot = undefined;

    /**
     * Extracted color hash.
     * @type {string}
     */
    this.color = undefined;

    /**
     * Bind scope.
     */
    this.onLoad = this.onLoad.bind(this);
  }

  onLoad() {
    this.imgRoot.classList.add('on');
  }

  connectedCallback() {
    const src = this.getAttribute('path');

    if (src) {
      const url = decodeURIComponent(src);
      const query = url ? url.split('?').pop() : '';
      const params = new URLSearchParams(query);

      this.color = params.get('color');

      this.style.backgroundColor = this.color;
    }

    this.innerHTML = `<img src="${src}" alt="${this.getAttribute('alt')}">`;

    this.imgRoot = this.querySelector('img');
    this.imgRoot.classList.add('fx');
    this.imgRoot.addEventListener('load', this.onLoad);
  }
}

window.customElements.define('o-image', HTMLOImage);