import manageMessage from "../../app.services/managers/message.mjs";
import meta from "../../app.services/route/meta.mjs";

/**
 * @param {*} $scope
 */
function ContactCtrl($scope) {
  meta.title('Contact');

  $scope.is_sending = false;
  $scope.is_sent = null;

  $scope.send = async () => {
    $scope.is_sending = true;

    const result = await manageMessage.send();

    $scope.$applyAsync(() => {
      $scope.is_sending = false;
      $scope.is_sent = !result.is_error;
    });
  };
}

ContactCtrl.$inject = ['$scope'];
ContactCtrl.resolve = {};

app.controller('ContactCtrl', ContactCtrl);
window.ContactCtrl = ContactCtrl;
