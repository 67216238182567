import meta from "../../app.services/route/meta.mjs";

function PricingCtrl() {
  meta.title('Pricing');
  // meta.colors([
  //   { min: 0, max: 60, color: undefined },
  //   { min: 60, max: 340, color: 'green' },
  //   { min: 340, color: 'light' }
  // ]);
}

PricingCtrl.$inject = [];
PricingCtrl.resolve = {};

app.controller('PricingCtrl', PricingCtrl);
window.PricingCtrl = PricingCtrl;
