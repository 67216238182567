// eslint-disable-next-line no-undef
export default class HTMLODrawer extends HTMLElement {
  constructor() {
    super();
  }

  isOn(is_on) {
    if (is_on) {
      this.style.display = 'grid';
    } else {
      this.style.display = 'none';
    }
  }

  renderDrawer() {
    return /*html*/`
    <o-avatar></o-avatar>
    <div class="field">
      <div class="label">Name</div>
      <o-text-input value="Page"></o-text-input>
    </div>
    <o-description-input value="Description..."></o-description-input>
    <hr>
    <div class="field">
      <div class="label">Public</div>
      <o-toggle-button></o-toggle-button>
    </div>
    <div class="field">
      <div class="label">Listed</div>
      <o-toggle-button></o-toggle-button>
    </div>
    <div class="field">
      <div class="label">Archived</div>
      <o-toggle-button></o-toggle-button>
    </div>
    <hr>
    <div class="field">
      <div class="label">Created</div>
      <div class="property">Dec 27, 2022</div>
    </div>
    <div class="field">
      <div class="label">Last Updated</div>
      <div class="property">Jul 17, 2023</div>
    </div>`;
  }

  connectedCallback() {
    this.innerHTML = /*html*/`
    <div class="header">
      <div class="label">Projects</div>

    </div>
    <div class="content">
      ${this.renderDrawer()}
    </div>`;

    const is_on = this.hasAttribute('on');

    this.isOn(is_on);
  }
}

window.customElements.define('o-drawer', HTMLODrawer);