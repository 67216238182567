/**
 * For declaring element configuration that can be selected by passing the name of the
 * function in the config property in the o-editor or o-projects element.
 */
export default class Configuration {
  constructor() {}

  get createComponents() {
    return [
      { id: 'this', menu: 'off' },
      { id: '#design-1', is_selected: false }
    ]
  }

  get createOrganization() {
    return [
      { id: 'o-modal', modal: 'new-project' }
    ]
  }

  /**
   * Configures the editor for observatory design.
   */
  get observatoryEditor() {
    return [
      { id: 'o-layers', is_on: true }
    ]
  }

  /**
   * Configures the editor for observatory projects.
   */
  get observatoryProjects() {
    return [
      { id: '', property: '' }
    ]
  }
}