// eslint-disable-next-line no-undef
export default class HTMLOUsersPresence extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.innerHTML = /*html*/`
    <div class="users">
      <div class="blue">
        <div>J</div>
      </div>
      <div class="green">
        <div>P</div>
      </div>
      <div class="purple">
        <div>G</div>
      </div>
      <div class="number">
        <div>+6</div>
      </div>
    </div>`;
  }
}

window.customElements.define('o-users-presence', HTMLOUsersPresence);