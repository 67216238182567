import manageUpdates from "../../app.services/managers/update.mjs";

app.directive('updates', [
  /**
   */
  () => {
    return {
      scope: {},
      restrict: 'E',
      replace: true,
      templateUrl: './app.directives/updates/updates.html',
      link: $scope => {
        $scope.updates = [];

        $scope.is_loading = true;

        const watchUpdates = props => {
          $scope.$applyAsync(() => {
            if (props.raw) {
              $scope.updates = props?.raw?.filter?.(update => update.is_active);

              $scope.is_loading = false;
            }
          });
        };

        manageUpdates.watch(watchUpdates);
        manageUpdates.getUpdates();

        $scope.$on('$destroy', () =>  {
          manageUpdates.unwatch(watchUpdates);
        });
      }
    };
  }
]);
