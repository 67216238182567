/**
 * Supporting string manipulations.
 */
export class CoreText {
  /**
   * To input appropriate string that returns '' instead of undefined.
   * @param {string | number} text
   * @returns {string}
   */
  toString(text) {
    if (!text) {
      return '';
    }

    return text.toString();
  }

  /**
   * Replaces underscore with a space.
   * @param {string} text
   */
  split(text) {
    return text?.replace(/_/g, () => ' ');
  }

  /**
   * Replaces dashes with a space.
   * @param {string} text
   */
  splitDash(text) {
    return text?.replace(/-/g, () => ' ');
  }

  /**
   * Replaces underscore with a dash.
   * @param {string} text
   */
  dash(text) {
    if (text && typeof text === 'string') {
      return text.replace(/_/g, () => '-');
    }

    return text;
  }

  /**
   * Capitalizes the first letter of the word.
   * @param {string} text
   */
  capitalize(text) {
    if (text) {
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    }
  }

  /**
   * Converts text to camel case.
   * @param {string} text
   */
  camelCase(text) {
    return text?.replace(/_([a-z])/g, (m, w) => w.toUpperCase());
  }

  /**
   * Returns first occurrence of text, in lower case, in array of objects by key or undefined.
   * @param {Array} array
   * @param {string} text
   */
  searchArray(array, text, id) {
    if (!text || typeof text !== 'string' || !array) {
      return;
    }

    const result = array.find(element => {
      const value = element[id] ? element[id].toLowerCase() : null;

      return value?.startsWith(text.toLowerCase());
    });

    return result;
  }

  /**
   * Returns first occurrence of text ending in array or undefined.
   * @deprecated
   * @param {Array} array
   * @param {string} text
   */
  searchArrayForEnding(array, text, id) {
    if (!text || !array) {
      return;
    }

    const result = array.find(element => {
      const value = element[id] ? element[id].toLowerCase() : null;

      return value?.startsWith(text.toLocaleLowerCase());
    });

    if (result) {
      const ending = result[id];

      return ending.slice(text.length);
    }
  }

  /**
   * Returns number from string.
   * @param {string} text
   * @returns {number}
   */
  extractNumber(text) {
    let number = parseFloat(text?.replace(/\D/g,''));

    return isNaN(number) ? 0 : number;
  }

  /**
   * Ends a string with a character if it's not already there.
   * @param {string} text
   * @param {string} end
   */
  endWith(text, end) {
    if (!text) {
      return text;
    }

    return text.endsWith(end) ? text : `${text}${end}`;
  }

  /**
   * Removes characters from end of string if they exist.
   * @param {string} text
   * @param {string} trim
   */
  trimEnd(text, trim) {
    if (!trim) {
      return text;
    }

    return text.slice(0, text.lastIndexOf(trim));
  }

  /**
   * Converts font weight to name.
   * @returns {string}
   */
  fontWeightToText(weight) {
    const weights = {
      100: 'Thin',
      200: 'Extra Light',
      300: 'Light',
      400: 'Regular',
      500: 'Medium',
      600: 'Semibold',
      700: 'Bold',
      800: 'Extra Bold',
      900: 'Black',
      undefined: ''
    };

    return weights[weight] ? weights[weight] : '';
  }

  /**
   * Converts font style to name.
   */
  fontStyleToText(style) {
    const styles = {
      'normal': '',
      'italic': 'Italic',
      'oblique': 'Oblique',
      undefined: ''
    };

    return styles[style] ? styles[style] : '';
  }
}

const coreText = new CoreText();

export default coreText;
