import { GraphQl } from "../graphql.mjs";

/**
 * @class QueryPost
 */
class QueryPost extends GraphQl {
  async getPost(id) {
    const result = await this.fetch(`
      query getPost($id: ID!) {
        post(id: $id) {
          _id
          title
          sub_title
          content
          category
          link
          cover
          accent_color
          primary_color
          is_active
          author {
            _id
            full_name
            display_name
            avatar
            avatar_virtual
          }
          editor {
            _id
            full_name
            display_name
            avatar
            avatar_virtual
          }
          created_at
          updated_at
        }
      }
    `, { id });

    return result;
  }

  async getPosts() {
    const result = await this.fetch(`
      query getPosts {
        posts {
          _id
          title
          sub_title
          content
          category
          link
          cover
          accent_color
          primary_color
          is_active
          author {
            _id
            full_name
            display_name
            avatar
            avatar_virtual
          }
          editor {
            _id
            full_name
            display_name
            avatar
            avatar_virtual
          }
          created_at
          updated_at
        }
      }
    `);

    return result;
  }
}

const queryPost = new QueryPost();

export default queryPost;
