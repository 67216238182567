// eslint-disable-next-line no-undef
export default class HTMLOBlogDesign extends HTMLElement {
  constructor() {
    super();

    /** @type {HTMLElement} */
    this.headerRoot = undefined;
    /** @type {HTMLElement} */
    this.pageRoot = undefined;

    this.label = this.getAttribute('label')
    this.width = this.getAttribute('width');
    this.height = this.getAttribute('height');
  }

  isSelected(is_selected) {
    if (is_selected) {
      this.headerRoot.style.display = 'grid';
      this.pageRoot.classList.add('on');
    } else {
      this.headerRoot.style.display = 'none';
      this.pageRoot.classList.remove('on');
    }
  }

  /**
   * Updates cursors position.
   * @param {number} x
   * @param {number} y
   * @param {number} z
   */
  position(x, y, z) {
    const X = typeof x === 'number' && !isNaN(x) ? x : 0;
    const Y = typeof y === 'number' && !isNaN(y) ? y : 0;
    const Z = typeof z === 'number' && !isNaN(z) ? z : 0;

    this.style.transform = `translate3d(${X}px, ${Y}px, ${Z}px)`;
  }

  connectedCallback() {
    this.innerHTML = /*html*/`
    <div class="header">
      <div class="fal fa-plus"></div>
      <div class="label">${this.label ? this.label : ''}</div>
      <div class="label">${this.width ? this.width : '0'} x ${this.height ? this.height : '0'}</div>
    </div>
    <div class="page">
      <div class="selection">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>`;

    this.headerRoot = this.querySelector('div.header');
    this.pageRoot = this.querySelector('div.page');

    const x = parseInt(this.getAttribute('x'));
    const y = parseInt(this.getAttribute('y'));
    const z = parseInt(this.getAttribute('z'));

    this.position(x, y, z);

    const is_selected = this.hasAttribute('selected');

    this.isSelected(is_selected);
  }
}

window.customElements.define('o-blog-design', HTMLOBlogDesign);