import ManagerBase  from './manager-base.mjs';
import queryBilling from '../api/queries/billing.mjs';
import viewServices from '../view/services.mjs';
class ManageBilling extends ManagerBase  {
  constructor() {
    super();

    this.queryBilling = queryBilling;

    this.props = {
      filter: 'year'
    };
  }

  async getPlans() {
    this.props.raw = await this.queryBilling.getPlans();

    this.filter();
  }

  filter() {
    if (this.props.raw) {
      this.props.plans = [];

      for (const plan of this.props.raw) {
        plan.price = plan.prices.find(price => price?.recurring?.interval === this.props.filter);

        this.props.plans.push(plan);
      }
    }

    this.trigger();
  }

  /**
   * Sets property by name and filters the list.
   * @param {String} name
   * @param {OProp} prop
   */
  setAndFilter(name, prop) {
    if (this.props) {
      this.props[name] = prop;

      console.log(name)

      this.filter();
    }
  }
}

const manageBilling = new ManageBilling();

export default manageBilling;

viewServices.set('manageBilling', manageBilling);