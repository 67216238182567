/**
 * @class CommonSystem
 */
class CommonSystem {
  getOS() {
    if (navigator.userAgent.indexOf("Win") != -1) {
      return 'win';
    }

    if (navigator.userAgent.indexOf("Mac") != -1) {
      return 'darwin';
    }

    if (navigator.userAgent.indexOf("Linux") != -1) {
      return 'linus';
    }

    if (navigator.userAgent.indexOf("Android") != -1) {
      return 'android';
    }

    if (navigator.userAgent.indexOf("like Mac") != -1) {
      return 'iOS';
    }
  }

  isMac() {
    return this.getOS() === 'darwin';
  }

  isWindows() {
    return this.getOS() === 'win';
  }
}

app.service('commonSystem', [CommonSystem]);
