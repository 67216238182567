/**
 * Adjust the local path depending on the target application.
 */
export class Path {
  constructor() {
    this.capacitor_protocol = 'capacitor://';
    this.file_protocol = 'file://';
    this.path_protocol = 'http://';

    this.window_location = window.location;
  }

  get origin() {
    let origin = this.window_location.origin;

    if (origin.indexOf(this.capacitor_protocol) !== -1) {
      origin = origin.replace(this.capacitor_protocol, this.path_protocol);
    }

    if (origin.indexOf(this.file_protocol) !== -1) {
      origin = origin.replace(this.file_protocol, 'http://localhost:8080');
    }

    return origin;
  }

  get href() {
    let href = this.window_location.href;

    if (href.indexOf(this.capacitor_protocol) !== -1) {
      href = href.replace(this.capacitor_protocol, this.path_protocol);
    }

    if (href.indexOf(this.file_protocol) !== -1) {
      href = href.replace(this.file_protocol, this.path_protocol);
    }

    return href;
  }

  get host() {
    const origin = this.window_location.origin;
    let host = this.window_location.host;

    if (origin.indexOf(this.file_protocol) !== -1) {
      host = 'localhost:8080';
    }

    return host;
  }

  get hostname() {
    const origin = this.window_location.origin;
    let hostname = this.window_location.hostname;

    if (origin.indexOf(this.file_protocol) !== -1) {
      hostname = 'localhost';
    }

    return hostname;
  }

  get protocol() {
    let protocol = this.window_location.protocol;

    if (protocol === 'capacitor:' || protocol === 'file:') {
      protocol = 'http:';
    }

    return protocol;
  }

  get pathname() {
    return this.window_location.pathname;
  }

  get port() {
    return this.window_location.port;
  }

  get socketProtocol() {
    if (this.window_location.protocol === 'capacitor:' || this.window_location.protocol === 'file:') {
      return 'ws';
    }

    return this.window_location.protocol === 'http:' ? 'ws' : 'wss';
  }
}

export default new Path();