export default class HTMLOMask extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.innerHTML = /*html*/`
    <div class="lines">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>`;
  }
}

window.customElements.define('o-mask', HTMLOMask);