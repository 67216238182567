// eslint-disable-next-line no-undef
export default class HTMLODesignVersion extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.innerHTML = '0.1.2';
  }


}

window.customElements.define('o-design-version', HTMLODesignVersion);