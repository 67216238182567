import ManagerBase  from './manager-base.mjs';
import queryUpdate from '../api/queries/update.mjs';
import viewServices from '../view/services.mjs';
import angularBridge from '../view/angular-bridge.mjs';

class ManageUpdates extends ManagerBase  {
  constructor() {
    super();

    this.queryUpdate = queryUpdate;
    this.angularBridge = angularBridge;
  }

  async getUpdates() {
    this.props = {
      sort: 'name',
      reverse: false,
      layout: false
    };
    this.props.raw = await this.queryUpdate.getUpdates();

    this.filter();
  }

  filter() {
    const orderByFilter = this.angularBridge.getOrderByFilter();

    this.props.updates = orderByFilter(
      this.props.raw,
      this.props.sort,
      this.props.reverse
    );

    this.trigger();
  }
}

const manageUpdates = new ManageUpdates();

export default manageUpdates;

viewServices.set('manageUpdates', manageUpdates);