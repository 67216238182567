/**
 * For declaring animation sequences that can be selected by passing the name of the
 * function in the animation property in the o-editor or o-projects element.
 */
export default class Animations {
  constructor() {}

  get createComponents() {
    return {
      duration: 6000,
      steps: [
        { id: 'o-cursor#user-1', point: { time:0, is_on: true }},
        { id: 'o-cursor#user-1', easing: 'easeOutCubic', start: { time:0, x: 0, y: 0 },
          end: { time:500, x: 200, y: 200 }},
        { id: 'o-cursor#user-1', point: { time:500, click: true }},
        { id: '#design-1', point: { time:500, is_selected: true }},
        { id: 'o-modal', point: { time:1000, modal: 'elements' }},
        { id: 'o-cursor#user-1', easing: 'easeOutCubic', start: { time:1200, x: 200, y: 200 },
          end: { time:1600, x: 190, y: 420 }},
        { id: 'o-cursor#user-1', point: { time:1600, click: true }},
        { id: 'o-cursor#user-1', easing: 'easeOutCubic', start: { time:2000, x: 190, y: 420 },
          end: { time:2600, x: 600, y: 160 }},
        { id: 'o-cursor#user-1', point: { time:2700, click: true }},
        { id: 'o-modal', point: { time:2700, modal: undefined }},
        { id: '#design-1', point: { time:2700, is_selected: false }},
      ],
      should_loop: true
    }
  }

  get test() {
    return {
      duration: 6000,
      steps: [
        { id: '#design-1', point: { time:0, is_selected: true }},
        { id: '#design-2', point: { time:0, is_selected: false }},
        { id: 'o-toolbar', point: { time:0, menu: undefined }},
        { id: 'o-number-input#width', point: { time:0, value: '0' }},
        { id: 'o-slider-input', point: { time:0, value: '50' }},
        { id: 'o-color-input', point: { time:0, value: '#ffffff' }},
        { id: 'o-text-input', point: { time:0, value: '' }},
        { id: 'o-toggle-button', point: { time:0, is_on: true }},
        { id: 'o-checkbox-button', point: { time:0, is_on: true }},
        { id: '#user-1', point: { time:0, is_on: true }},
        { id: '#user-2', point: { time:0, is_on: true }},
        { id: '#user-1', easing: 'easeOutCubic', start: { time:0, x: 0, y: 0 },
          end: { time:1000, x: 200, y: 200 }},
        { id: '#user-1', easing: 'easeOutCubic', start: { time:2000, x: 200, y: 200 },
          end: { time:3000, x: 300, y: 300 }},
        { id: '#user-1', easing: 'easeOutCubic', start: { time:3001, x: 300, y: 300 },
          end: { time:4000, x: 0, y: 300 }},
        { id: 'o-toolbar', point: { time:3500, menu: 'page' }},
        { id: '#user-1', point: { time:4001, click: false }},
        { id: 'o-toggle-button', point: { time:4000, is_on: false }},
        { id: 'o-checkbox-button', point: { time:4000, is_on: false }},
        { id: 'o-text-input', point: { time:4000, value: 'Page' }},
        { id: 'o-color-input', point: { time:4000, value: '#0099ff' }},
        { id: 'o-slider-input', point: { time:4000, value: '75' }},
        { id: 'o-number-input#width', point: { time:4000, value: '400' }},
        { id: '#design-1', point: { time:4000, is_selected: false }},
        { id: '#design-2', point: { time:4000, is_selected: true }},
        { id: '#user-2', start: { time:500, x: 0, y: 0 }, end: { time:3000, x: 0, y: 400 }},
        { id: 'o-toolbar', point: { time:4500, menu: 'element' }},
      ],
      should_loop: true
    }
  }

  /**
   * Configures the animation for observatory projects.
   */
  get observatoryProjects() {
    return {
      duration: 0,
      steps: [],
      should_loop: false
    }
  }

  /**
   * COnfigures the animation for observatory editor.
   */
  get observatoryEditor() {
    return {
      duration: 0,
      steps: [],
      should_loop: false
    }
  }

  get marketingProjects() {
    return {
      duration: 4000,
      steps: [
        { id: 'this', easing: 'easeOutCubic', start: { time:0, x: 0, y: 0 },
          end: { time:1000, x: -200, y: -200 }},
        { id: 'o-cursor#user-1', point: { time:0, is_on: true }},
        { id: 'o-cursor#user-1', easing: 'easeOutCubic', start: { time:0, x: 0, y: 0 },
          end: { time:1000, x: 200, y: 200 }},
        { id: 'o-cursor#user-1', easing: 'easeOutCubic', start: { time:2000, x: 200, y: 200 },
          end: { time:3000, x: 300, y: 300 }},
        { id: 'o-cursor#user-1', point: { time:3000, click: true }},
        { id: 'this', easing: 'easeOutCubic', start: { time:3000, x: -200, y: -200 },
          end: { time:4000, x: -200, y: -500 }},
        { id: 'o-cursor#user-1', easing: 'easeOutCubic', start: { time:3001, x: 300, y: 300 },
          end: { time:4000, x: 0, y: 300 }},
        { id: 'o-cursor#user-1', point: { time:4001, is_on: false }},
      ],
      should_loop: true
    }
  }
}