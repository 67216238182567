import meta from "../../app.services/route/meta.mjs";

function FeaturesCtrl() {
  meta.title('Features');
  // meta.colors([
  //   { min: 0, max: 60, color: undefined },
  //   { min: 60, max: 340, color: 'dark-pink' },
  //   { min: 340, color: 'light' }
  // ]);
}

FeaturesCtrl.$inject = [];
FeaturesCtrl.resolve = {};

app.controller('FeaturesCtrl', FeaturesCtrl);
window.FeaturesCtrl = FeaturesCtrl;
