// eslint-disable-next-line no-undef
export default class HTMLOCheckboxButton extends HTMLElement {
  constructor() {
    super();
  }

  /**
   * Toggles the element on/off with effects.
   * @param {boolean} is_on
   */
  isOn(is_on) {
    if (is_on) {
      this.classList.add('on');
    } else {
      this.classList.remove('on');
    }
  }

  connectedCallback() {
    /**
     * Sets the default on state.
     */
    const is_on = this.getAttribute('on');

    if (is_on) {
      this.classList.add('on');
    }
  }
}

window.customElements.define('o-checkbox-button', HTMLOCheckboxButton);