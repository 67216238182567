import meta from "../../app.services/route/meta.mjs";

function IOsCtrl() {
  meta.title('Download iOS');
  // meta.colors([
  //   { min: 0, max: 60, color: undefined },
  //   { min: 60, max: 500, color: 'pink' },
  //   { min: 500, color: 'light' }
  // ]);
}

IOsCtrl.$inject = [];
IOsCtrl.resolve = {};

app.controller('IOsCtrl', IOsCtrl);
window.IOsCtrl = IOsCtrl;
