import manageBlog from "../../app.services/managers/blog.mjs";

app.directive('blog.posts', [
  /**
   */
  () => {
    return {
      scope: {},
      restrict: 'E',
      replace: true,
      templateUrl: './app.directives/blog/posts.html',
      link: $scope => {
        $scope.ads = [];

        $scope.is_loading = true;

        const watchPosts = props => {
          $scope.$applyAsync(() => {
            if (props.raw) {
              $scope.posts = props?.raw?.filter?.(post => post.is_active);

              $scope.is_loading = false;
            }
          });
        };

        manageBlog.watch(watchPosts);
        manageBlog.getPosts();

        $scope.$on('$destroy', () =>  {
          manageBlog.unwatch(watchPosts);
        });
      }
    };
  }
]);
