import meta from "../../app.services/route/meta.mjs";

function UpdatesCtrl() {
  meta.title('Updates');
  // meta.colors([
  //   { min: 0, max: 60, color: undefined },
  //   { min: 60, max: 340, color: 'pink' },
  //   { min: 340, color: 'light' }
  // ]);
}

UpdatesCtrl.$inject = [];
UpdatesCtrl.resolve = {};

app.controller('UpdatesCtrl', UpdatesCtrl);
window.UpdatesCtrl = UpdatesCtrl;
