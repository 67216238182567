import meta from "../../app.services/route/meta.mjs";

function PrivacyCtrl() {
  meta.title('Privacy');
}

PrivacyCtrl.$inject = [];
PrivacyCtrl.resolve = {};

app.controller('PrivacyCtrl', PrivacyCtrl);
window.PrivacyCtrl = PrivacyCtrl;
