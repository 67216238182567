import viewText from "../app.services/core/text.mjs";

app.directive('tooltip', ['$window',
  /**
   * @param {*} $window
   */
  ($window) => {
    return {
      scope: {
        tooltip: '@',
        shortcut: '@?',
        subtitle: '@?',
        mode: '@' // float - will attache externally and move to element position.
      },
      restrict: 'A',
      link: ($scope, ele, attr) => {
        const is_touch_device = ('ontouchstart' in document.documentElement);

        let body = null;
        let element = null;

        const hoverOut = () => {
          element.css('display', 'none');
        };

        const hoverIn = () => {
          const position = ele[0].getBoundingClientRect();

          element.css('top', `${position.y}px`);
          element.css('left', `${position.width + position.x + 8}px`);
          element.css('display', 'block');
        }

        const buildNode = () => {
          /**
           * Attach to parent element or body based on mode.
           */
          if ($scope.mode === 'float') {
            body = angular.element($window.document.body);
            element = angular.element('<span class="tooltip-root"></span>');

            body?.append?.(element);

            ele.bind('mouseover', hoverIn);
            ele.bind('mouseleave', hoverOut);
          } else {
            element = angular.element('<span></span>');

            ele.append(element);
          }
        }

        const render = () => {
          const tag = attr.delay ? '<span class="delay">' : '<span>';
          // const key_tag = shortcut?.key ? `<span class="shortcut">${shortcuts.getKey(shortcut.key)}</span>` : '';
          // const combo_tag = shortcut?.combo ? `<span class="shortcut">${shortcuts.getKey(shortcut.combo)}</span>` : '';
          const subtitle = $scope.subtitle ? `<span class="subtitle">${viewText.capitalize($scope.subtitle)}</span>` : '';

          /**
           * Don't enable tooltips on touch devices.
           * TODO: remove extra span.
           */
          if (!is_touch_device && $scope.tooltip) {
            element[0].innerHTML = `${tag}${$scope.tooltip} ${subtitle}</span>`;
          }
        }

        if (!is_touch_device && $scope.tooltip) {
          buildNode();
        }

        render();

        // shortcuts.watch($scope.shortcut, render);

        $scope.$watch('tooltip', (tooltip, old) => {
          if (tooltip !== old) {
            render();
          }
        });

        $scope.$on('$destroy', () => {
          // shortcuts.unwatch(render);

          element?.remove();

          ele.unbind('mouseover', hoverIn);
          ele.unbind('mouseleave', hoverOut);
        });
      }
    };
  }
]);
