// eslint-disable-next-line no-undef
export default class HTMLOProjectsList extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.innerHTML = /*html*/`
    <div class="ads">
      <div class="blue">
      <o-gradient color-1="#9900ff" color-2="#B100F9"></o-gradient>
        <div class="info">
          <div class="title">New Features</div>
          <div class="sub-title">Checkout all the new things!</div>
        </div>
      </div>
      <div class="green">
        <o-gradient color-1="#C900F2" color-2="#F800E4"></o-gradient>
        <div class="info">
          <div class="title">Guides</div>
          <div class="sub-title">Learn how to use Octo</div>
        </div>
      </div>
    </div>
    <div class="controls">
      <div class="title">Recent</div>
      <div class="figure-button save">
        <div class="far fa-plus"></div>
        New Project
      </div>
      <div class="search">
        <div class="fal fa-search"></div>
        <div>Search projects...</div>
      </div>
      <div class="filters">
        <o-dropdown-input value="Name"></o-dropdown-input>
      </div>
    </div>
    <div class="tile-list">
      <div class="tile">
        <div class="tile green">
        <o-image path="https://s3.amazonaws.com/octo.coffee/marketing/fdbf7590-37bf-4bd3-be55-f9a5657636d6.jpeg%3Fcolor%3D%23241d24"></o-image>
        </div>
        <div class="title">Marketing Website</div>
      </div>
      <div class="tile">
        <div class="tile blue"></div>
        <div class="title">Calendar App</div>
      </div>
      <div class="tile">
        <div class="tile"></div>
        <div class="title">Component Brainstorming</div>
      </div>
      <div class="tile">
        <div class="tile"></div>
        <div class="title">Prototype</div>
      </div>
      <div class="tile">
        <div class="tile"></div>
        <div class="title">My Project</div>
      </div>
      <div class="tile">
        <div class="tile"></div>
        <div class="title">My Project</div>
      </div>
    </div>`;
  }

}

window.customElements.define('o-projects-list', HTMLOProjectsList);