// eslint-disable-next-line no-undef
export default class HTMLODescriptionInput extends HTMLElement {
  constructor() {
    super();

    this._value;
  }

  /**
   * @param {string} value
   */
  set value(value) {
    this._value = value;

    this.renderValue();
  }

  /**
   * @returns {string | undefined}
   */
  get value() {
    return this._value;
  }

  renderValue() {
    this.innerHTML = /*html*/`${this._value ? this._value : ''}`;
  }

  connectedCallback() {
    this.value = this.getAttribute('value');
  }
}

window.customElements.define('o-description-input', HTMLODescriptionInput);