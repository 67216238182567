import Animations from "./animations.mjs";
import Configurations from "./configurations.mjs";

export default class HTMLOAnimation extends HTMLElement {
  constructor() {
    super();

    /**
     * Name of the animation configuration function.
     */
    this.animation = this.getAttribute('animation');

    /**
     * Name of the element configuration function.
     */
    this.config = this.getAttribute('config');

    /**
     * Cache of animation sequences.
     */
    this.animation_cache = new Animations();
    this.configuration_cache = new Configurations();

    /**
     * The total duration of the animation.
     * @type {number}
     */
    this.total_duration = 0;
    /**
     * Indicates if the animation should loop.
     * @type {boolean}
     */
    this.should_loop = false;

    this.element_cache = {};
  }

  /**
   * Selects and caches elements for manipulation.
   * @param {OAnimation} animation
   */
  cacheElements(animation) {
    if (animation?.length) {
      for (const step of animation) {
        if (step.id === 'this') {
          this.element_cache[step.id] = this;
        } else {
          this.element_cache[step.id] = this.querySelector(step.id);
        }

        if (!this.element_cache[step.id]) {
          console.error(`Unable to select ${step.id} element in animation.`);
        }
      }
    }
  }

  /**
   * Calculates percent complete of an elapsed time (using internal interval constant).
   * @param {number} duration The total time needed to complete the process.
   * @param {number} elapsed The elapsed time.
   * @returns {number}
   */
  getPercent(duration, elapsed) {
    if (elapsed === undefined || isNaN(elapsed)) {
      return 0;
    }

    return elapsed > duration ? 1 : (Math.round((elapsed / duration) * 1000) / 1000);
  }

  /**
   * Returns path segments based on elapsed time and easing.
   * @param {number} start The starting position.
   * @param {number} end The ending position.
   * @param {number} duration The total time needed to perform process.
   * @param {number} elapsed The elapsed time.
   * @param {string} [easing] The name of the easing function.
   * @returns {number}
   */
  getSegments(start, end, duration, elapsed, easing) {
    const percent = this.getPercent(duration, elapsed);
    const distance = end - start;
    const eased = easing && this[easing] ? this[easing](percent) : percent;
    const path = start + (eased * distance);

    return path;
  }

  /**
   * Moves an element.
   * @param {string} id
   * @param {OAnimationPositionStep} start
   * @param {OAnimationPositionStep} end
   * @param {number} total_elapsed
   * @param {string} easing
   */
  move(id, start, end, total_elapsed, easing) {
    const duration = end.time - start.time;
    const elapsed = total_elapsed - start.time;

    let x = 0;
    let y = 0;
    let z = 0;

    if (typeof start.x === 'number' && typeof end.x === 'number') {
      x = this.getSegments(start.x, end.x, duration, elapsed, easing);
    }

    if (typeof start.y === 'number' && typeof end.y === 'number') {
      y = this.getSegments(start.y, end.y, duration, elapsed, easing);
    }

    if (typeof start.z === 'number' && typeof end.z === 'number') {
      z = this.getSegments(start.z, end.z, duration, elapsed, easing);
    }

    this.element_cache[id]?.position?.(x, y, z);
  }

  /**
   * @param {string} id
   * @param {OAnimationPointStep} point
   */
  point(id, point) {
    if ('is_on' in point) {
      this.element_cache[id]?.isOn?.(point.is_on);
    }

    if ('is_selected' in point) {
      this.element_cache[id]?.isSelected?.(point.is_selected);
    }

    if ('click' in point) {
      this.element_cache[id]?.click();
    }

    if ('value' in point) {
      this.element_cache[id].value = point.value;
    }

    if ('menu' in point) {
      this.element_cache[id]?.setMenu(point.menu);
    }

    if ('modal' in point) {
      this.element_cache[id]?.setModal(point.modal);
    }
  }

  /**
   * @param {number} elapsed
   * @param {OAnimation} animation
   */
  pick(elapsed, animation) {
    if (animation?.length) {
      for (const step of animation) {
        /**
         * Find the step by elapsed time.
         */
        if (step.start?.time <= elapsed && step.end?.time >= elapsed) {
          this.move(step.id, step.start, step.end, elapsed, step.easing);
        }

        if (step.point?.time <= elapsed && (step.point?.time + 100) >= elapsed) {
          this.point(step.id, step.point);
        }
      }
    }
  }

  /**
   * @param {number} start_time
   * @param {number} end_time
   * @param {OAnimation} animation
   */
  tick(start_time, end_time, animation) {
    let start = undefined;

    const tick = timestamp => {
      if (start === undefined) {
        start = timestamp;
      }

      const duration = end_time - start_time;
      const elapsed = Math.round(timestamp - start);
      const percent = this.getPercent(duration, elapsed);

      this.pick(elapsed, animation);

      if (percent < 1) {
        window.requestAnimationFrame(tick);
      } else if (this.should_loop) {
        this.tick(start_time, end_time, animation);
      }
    }

    window.requestAnimationFrame(tick);
  }

  /**
   * Configures elements default state.
   */
  configureElements() {
    const configuration = this.configuration_cache[this.config];

    if (configuration?.length) {
      for (const config of configuration) {
        if (config.id === 'this') {
          this.element_cache[config.id] = this;
        } else {
          this.element_cache[config.id] = this.querySelector(config.id);
        }

        this.point(config.id, config);
      }
    }
  }

  /**
   * Starts the animation process.
   */
  startAnimation() {
    const animation = this.animation_cache[this.animation];

    if (animation) {
      this.start(animation.duration, animation.steps, animation.should_loop);
    }
  }

  /**
   * Runs the animation sequence.
   * @param {number} duration
   * @param {OAnimation} animation
   * @param {boolean} should_loop
   */
  start(duration, animation, should_loop) {
    this.total_duration = duration;
    this.should_loop = should_loop;

    if (animation?.length && this.total_duration > 0) {
      this.cacheElements(animation);
      this.tick(0, this.total_duration, animation);
    }
  }

  /**
   * @param {number} x 0-1
   * @returns {number}
   */
  easeInSine(x) {
    return 1 - Math.cos((x * Math.PI) / 2);
  }

  /**
   * @param {number} x 0-1
   * @returns {number}
   */
  easeOutSine(x) {
    return Math.sin((x * Math.PI) / 2);
  }

  /**
   * @param {number} x 0-1
   * @returns {number}
   */
  easeInOutSine(x) {
    return -(Math.cos(Math.PI * x) - 1) / 2;
  }

  /**
   * @param {number} x 0-1
   * @returns {number}
   */
  easeInCubic(x) {
    return x * x * x;
  }

  /**
   * @param {number} x 0-1
   * @returns {number}
   */
  easeOutCubic(x) {
    return 1 - Math.pow(1 - x, 3);
  }

  /**
   * @param {number} x 0-1
   * @returns {number}
   */
  easeInOutCubic(x) {
    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
  }

  /**
   * @param {number} x 0-1
   * @returns {number}
   */
  easeInQuint(x) {
    return x * x * x * x * x;
  }

  /**
   * @param {number} x 0-1
   * @returns {number}
   */
  easeOutQuint(x) {
    return 1 - Math.pow(1 - x, 5);
  }
}

/**
 * Defined for tests.
 */
window.customElements.define('o-animation', HTMLOAnimation);