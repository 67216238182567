// eslint-disable-next-line no-undef
export default class HTMLOCursor extends HTMLElement {
  constructor() {
    super();

    /**
     * Name of a user tracking the cursor.
     * @type {string | undefined}
     */
    this._name = undefined;
    /**
     * @type {boolean}
     */
    this.is_on = false;

    /**
     * Bind Scope.
     */
    this.removeClick = this.removeClick.bind(this);
  }

  /**
   * @returns {string | undefined}
   */
  get name() {
    return this._name;
  }

  /**
   * Sets name property.
   * @param {string} name
   */
  set name(name) {
    this._name = name;

    this.renderName();
  }

  renderName() {
    this.innerHTML = /*html*/`<div>${this._name}</div>`;
  }

  /**
   * Updates cursors position.
   * @param {number} x
   * @param {number} y
   * @param {number} z
   */
  position(x, y, z) {
    this.style.transform = `translate3d(${x}px, ${y}px, ${z}px)`;
  }

  /**
   * Toggles the element on/off with effects.
   * @param {boolean} is_on
   */
  isOn(is_on) {
    if (is_on) {
      this.style.display = 'block';
    } else {
      this.style.display = 'none';
    }
  }

  /**
   * Triggers the click animation on the cursor.
   */
  click() {
    this.classList.add('click');
  }

  /**
   * Removes the click animation after it's finished.
   */
  removeClick() {
    this.classList.remove('click');
  }

  connectedCallback() {
    this.innerHTML = /*html*/`
    <div class="pointer"></div>
    <div class="label">${this.getAttribute('name')}</div>`;

    const color = this.getAttribute('color');
    const type = this.hasAttribute('pointer');

    if (color) {
      this.classList.add(color);
    }

    if (type) {
      this.classList.add('pointer');
    }

    this.addEventListener('animationend', this.removeClick, false);

    /**
     * Cursors are off by default.
     */
    this.isOn(false);
  }
}

window.customElements.define('o-cursor', HTMLOCursor);