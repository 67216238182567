import Discrete from "../../app.services/model/discrete.mjs";

app.directive('discrete.tapper', [
  /**
   */
  () => {
    return {
      scope: {
        prop: '@',
        service: '@',
        get: '@',
        set: '@',
        watch: '@',
        corners: '='
      },
      restrict: 'E',
      replace: true,
      templateUrl: './app.directives/controls/tapper.html',
      link: $scope => {
        const defaultFn = () => {
          $scope.model = false;
        };

        const discrete = new Discrete($scope, defaultFn);

        $scope.position = () => {
          const value = $scope.model === 'month' ? 1 : 0;

          return {
            width: 'calc(50% - 3px)',
            transform: `translate3d(${value * 100}%, 0, 0)`,
            display: 'block'
          }
        };

        $scope.select = prop => {
          $scope.model = prop;

          discrete.set();
        };

        $scope.isSelected = prop => {
          return $scope.model === prop;
        };

        $scope.$on('$destroy', () => {
          discrete.destroy();
        });
      }
    };
  }]);