/**
 * Service manager from DOM components.
 */
export class ViewServices {
  constructor() {
    this.services = {};
  }

  /**
   * Returns service by name.
   * @param {string} name
   * @returns {*}
   */
  get(name) {
    return this.services[name];
  }

  /**
   * Sets service by name making it available to DOM components.
   * @param {string} name
   * @param {*} service
   */
  set(name, service) {
    if (this.services[name]) {
      throw `${name} service already registered with the client services.`;
    }

    this.services[name] = service;
  }

  /**
   * Clears the service list.
   */
  clear() {
    this.services = {};
  }
}

export default new ViewServices();