app.config(['$stateProvider', '$locationProvider', '$urlRouterProvider', '$compileProvider',
  ($stateProvider, $locationProvider, $urlRouterProvider, $compileProvider) => {
    $locationProvider.html5Mode(true);

    $urlRouterProvider.otherwise('/404');

    $stateProvider
      .state('404', {
        url: '/404',
        templateUrl: 'app/404/404.html'
      })
      .state('sample', {
        url: '/sample',
        templateUrl: 'app/main/sample.html'
      })
      .state('home', {
        url: '/',
        templateUrl: 'app/main/home.html',
        controller: 'HomeCtrl'
      })
      .state('blog', {
        url: '/blog',
        templateUrl: 'app/main/blog.html',
        controller: 'BlogCtrl'
      })
      .state('pricing', {
        url: '/pricing',
        templateUrl: 'app/main/pricing.html',
        controller: 'PricingCtrl'
      })
      .state('features', {
        url: '/features',
        templateUrl: 'app/main/features.html',
        controller: 'FeaturesCtrl'
      })
      .state('updates', {
        url: '/updates',
        templateUrl: 'app/main/updates.html',
        controller: 'UpdatesCtrl'
      })
      .state('company', {
        url: '/company',
        templateUrl: 'app/main/company.html',
        controller: 'CompanyCtrl'
      })
      .state('android', {
        url: '/android',
        templateUrl: 'app/platforms/android.html',
        controller: 'AndroidCtrl'
      })
      .state('ios', {
        url: '/ios',
        templateUrl: 'app/platforms/ios.html',
        controller: 'IOsCtrl'
      })
      .state('macos', {
        url: '/macos',
        templateUrl: 'app/platforms/macos.html',
        controller: 'MacOsCtrl'
      })
      .state('windows', {
        url: '/windows',
        templateUrl: 'app/platforms/windows.html',
        controller: 'WindowsCtrl'
      })
      .state('contact', {
        url: '/contact',
        templateUrl: 'app/resources/contact.html',
        controller: 'ContactCtrl'
      })
      .state('guides', {
        url: '/guides',
        templateUrl: 'app/resources/guides.html',
        controller: 'GuidesCtrl'
      })
      .state('terms', {
        url: '/terms',
        templateUrl: 'app/legal/terms.html',
        controller: 'TermsCtrl'
      })
      .state('privacy', {
        url: '/privacy',
        templateUrl: 'app/legal/privacy.html',
        controller: 'PrivacyCtrl'
      });

    $compileProvider.imgSrcSanitizationWhitelist(/^\s*((https?|ftp|file|blob|chrome-extension):|data:image\/)/);
  }
]);
